import React, { Fragment, PureComponent } from 'react'
import { Progress } from 'antd';
import 'antd/dist/antd.css'
import butterfly from '../../assets/image/login/butterfly.png'
import fabric from '../../assets/image/login/fabric.png'
import grass from '../../assets/image/login/grass.png'
import './index.scss'
export default class index extends PureComponent {
    state={
        alertStyle:{display:'none'},
        alertClass:'',
        alertContent:'',
        iptPage:{},
        loadingPage:{display:'none'},
        iptClass:'ipt',
        btnClass:'bottomButton',
        placeholderContent:'输入你的姓名',
        percent:0,
        flag:true,
        bgcStyle:{},
        finishContent:'报告生成中'
    }
    monitorChange=(event)=>{
        if(this.symbol_input.value.length<=6){
            this.setState({alertStyle:{display:'none'}})
        }
    }
    submit=()=>{
        const {percent} = this.state
        let count = percent
        if(this.symbol_input.value.length>6){
            this.setState({alertStyle:{},alertClass:'alert animate__animated animate__fadeInUp animated__fast'})
        }
        if(this.symbol_input.value.length===0){
            this.setState({placeholderContent:'输入不能为空'})
            // this.setState({alertStyle:{},alertClass:'alert animate__animated animate__fadeInUp animated__fast'})
        }
        if(this.symbol_input.value.length<=6&&this.symbol_input.value.length>0){
            localStorage['input']=`${this.symbol_input.value}`
            this.setState({iptClass:'ipt animate__animated animate__fadeOut animated__fast'})
            this.setState({btnClass:'bottomButton animate__animated animate__fadeOut animated__fast'})
            setTimeout(()=>{
                this.setState({alertStyle:{display:'none'},alertClass:'alert'})
                this.setState({iptPage:{display:'none'}})
                this.setState({loadingPage:{}})
                let dots = ''
                setInterval(() => {
                    dots = dots + '.'
                    if(dots==='....'){
                        dots = ''
                    }
                    this.setState({finishContent:`报告生成中${dots}`})
                }, 500);
                const timer = setInterval(() => {
                    count++
                    this.setState({percent:count})
                    if(count>=100){
                        clearInterval(timer)
                        this.setState({bgcStyle:{display:'none'}})
                        this.props.history.replace('/Result')
                    }
                }, 30);
            },1000)
        }
    }

    onfocus=()=>{
        this.setState({placeholderContent:''})
    }

    onblur=()=>{
        this.setState({placeholderContent:'输入你的姓名'})
    }
    render() {
        const {alertStyle,alertClass,iptPage,iptClass,btnClass,loadingPage,placeholderContent,percent,bgcStyle,finishContent} = this.state
        return (
            <Fragment>
                <div className="background" style={bgcStyle}>
                    <div className={iptClass} style={iptPage}>
                        <img src={grass} alt="err" id="grass"/>
                        <div className="halo"></div>
                        <input type="text" placeholder={placeholderContent} id="symbol_input" ref={currentNode=>this.symbol_input=currentNode} onKeyUp={this.monitorChange} onFocus={this.onfocus} onBlur={this.onblur}/>
                        <div id="alert" style={alertStyle} className={alertClass}>不可超过6个字</div>
                    <div className={btnClass} onClick={this.submit} style={iptPage}>
                            <img src={butterfly} alt="err" id="butterfly"/>
                            <span id="start_adventure">解锁报告</span>
                            <img src={fabric} alt="err"/>
                            <div className="slash1"></div>
                            <div className="slash2"></div>
                    </div>
                    </div>
                    <div id="loadingPage" style={loadingPage}>
                    <div className="progressBar">
                        <Progress
                                percent={percent} 
                                status="active" 
                                strokeColor="#83FFF8"
                                trailColor="#4C909E"
                                strokeWidth="2px"
                                showInfo={false}
                            />
                        <span className="finish">{finishContent}</span>
                    </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}
