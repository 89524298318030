import React, { Component } from 'react'
import music from '../../assets/music/music5.mp3'
import Pubsub from 'pubsub-js'
import musicIcon from '../../assets/image/homePage/music.png'
import firefly1 from '../../assets/image/homePage/firefly_good.gif'
import firefly2 from '../../assets/image/homePage/firefly_good1.gif'
import './index.scss'
// 
export default class Music extends Component {
    // 初始化旋转角
    // 将样式存在状态中
    state = {
        musicStyle: { transform: `rotate(0deg)`,top:'15px' },
        flag: true,
        degree: 0,
        emptyStyle:{display:'none'},
        speakerStyle:{display:'none'},
        musicClass:'animate__animated shakeY1',
        fireflyStyle:{},
        controlFlag:true,
        top:15,
        direction:'up'
    }
    /**
     * 点击事件
     */
    changeState = () => {
        console.log(this.musicIcon.clientHeight)
        const { flag,controlFlag } = this.state
        Pubsub.publish('flag',flag)
        //点击事件触发时清空定时器，并修改flag状态
        console.log(flag)
        clearInterval(this.timer)
        this.setState({
            flag: !flag
        })
        if(flag===true&&controlFlag===true){
            this.setState({emptyStyle:{display:'none'},controlFlag:false})
        }
        if(flag===true){
            this.audio.play()
            this.setState({musicClass:'animate__animated shakeY1'})
            this.setState({fireflyStyle:{}})
            this.timer = setInterval(()=>{
                // console.log(preventTop,direction)
                if(this.direction==='up'){
                    this.preventTop=this.preventTop-0.20
                    if(this.preventTop<=12){
                        this.direction='down'
                    }
                }
                if(this.direction==='down'){
                    this.preventTop=this.preventTop+0.20
                    if(this.preventTop>=18){
                        this.direction='up'
                    }
                }
                this.setState({musicStyle:{transform: `rotate(0deg)`,top:`${this.preventTop}px`}})
            },23)
        }
        if(flag ===false){
            this.audio.pause();
            //暂停时停止音乐上下浮动
            this.setState({musicClass:''})
            //暂停时清除萤火虫
            this.setState({fireflyStyle:{display:'none'}})
            //停止音符上下浮动
            clearInterval(this.timer)
        }
        //通过flag判断状态，false时开启定时器,div开始转动
        if (flag === true) {
            // this.timer = setInterval(() => {
            //     this.setState({
            //         musicStyle: { color: 'red', transform: `rotate(${this.state.degree}deg)` },
            //         degree: this.state.degree + 1,
                    
            //     })
            // }, 20)
            
            setTimeout(()=>{
                this.setState({speakerStyle:{}})
            },1000)
        }
    }
    componentDidMount() {

        //问题页面控制音乐组件播放
        this.token=Pubsub.subscribe("play",(_,data)=>{
            if(data.flag===true){
                this.setState({flag:true})
                this.audio.play()
                window.localStorage.removeItem('play')
            }
            else{
                this.setState({flag:false})
                this.audio.pause();
                window.localStorage.removeItem('play')
            }
        })


        
        setTimeout(()=>{
            this.setState({emptyStyle:{}})
        },7000)
        //组件挂载时设置定时器，定时器控制角度++
        //const { degree } = this.state
        // setInterval(() => {
        //     this.setState({
        //         musicStyle: { color: 'red', transform: `rotate(${this.state.degree}deg)` },
        //         degree: this.state.degree + 1
        //     })
        // }, 20)
        const {top} = this.state
        this.preventTop = top
        this.direction = 'up'
        this.timer = setInterval(()=>{
            // console.log(preventTop,direction)
            if(this.direction==='up'){
                this.preventTop=this.preventTop-0.20
                if(this.preventTop<=12){
                    this.direction='down'
                }
            }
            if(this.direction==='down'){
                this.preventTop=this.preventTop+0.20
                if(this.preventTop>=18){
                    this.direction='up'
                }
            }
            this.setState({musicStyle:{transform: `rotate(0deg)`,top:`${this.preventTop}px`}})
        },23)

     
    }
    componentWillUnmount(){
        Pubsub.unsubscribe(this.token)
    }
    componentDidUpdate(){
        const flag = localStorage.getItem("try_again")
        if(flag==='flag'){
            setTimeout(()=>{
                this.setState({emptyStyle:{}})
                this.setState({flag:true})
            },7000)
            window.localStorage.removeItem('try_again')
        }
  
    }
    render() {
        const { emptyStyle,musicClass,fireflyStyle } = this.state
        
        return (
            <>  
                <div>
                {/* <div style={speakerStyle}> */}

                    <div className='demo' style={this.state.musicStyle} >
                        <div id="changeIconState">
                            <img src={musicIcon} alt="err" id="musicIcon" className={musicClass} ref={node=>this.musicIcon=node}/>
                        </div>
                    </div>
                    <div className="music_empty" onClick={this.changeState}></div>
                <img src={firefly1} alt="err" id="firefly1" style={fireflyStyle}/>
                <img src={firefly1} alt="err" id="firefly2" style={fireflyStyle}/>
                <img src={firefly2} alt="err" id="firefly3" style={fireflyStyle}/>

                </div>
                
                <audio
                    ref={(a)=>{
                        this.audio = a
                    }}
                    className="audio"
                    src={music}
                    loop="loop"
                ></audio>
                <div className="cheat_interactive" onClick={this.changeState} style={emptyStyle}></div>
                {/* cheatinteractive */}
            </>
        )
    } 
}
