import React, { Component } from 'react'
import './index.scss'
import QueueAnim from 'rc-queue-anim';
import PubSub from 'pubsub-js';
import Slash from '../../../assets/image/question/Slash.png'
import Frame24 from '../../../assets/image/question/24.png'
import 'animate.css'
import Frame23 from '../../../assets/image/question/23.png'
// import gif_mdc1 from '../../../assets/image/question/firefly_mdc1.gif'
// import firefly_mdc_new from '../../../assets/image/question/firefly_mdc_new.gif'
const base = document.documentElement.clientHeight
export default class FiveQuestion extends Component {
    state = {
        five_question_title: "five_question_title",
        five_question_one: "five_question_one",
        five_question_two: "five_question_two",
        five_question_three: "five_question_three",
        five_question_four: "five_question_four",
        // coverPage_five:"coverPage_five",
        choice: '',
        isClicked: false
    }
    five_previous_question = React.createRef()
    five_question_options = React.createRef()
    five_question_title = React.createRef()
    // coverPage_five=React.createRef()
    optionOne = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(1, 0, 2, 1, 1)
            this.setState({
                five_question_two: "five_question_two animate__animated  animate__fadeOutDown",
                five_question_three: "five_question_three animate__animated  animate__fadeOutDown",
                five_question_four: "five_question_four animate__animated  animate__fadeOutDown",
                choice: 'A',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    five_question_one: "five_question_one animate__animated  animate__fadeOutDown",
                    five_question_title: "five_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_five:"coverPage_five animate__animated  animate__fadeOutDown",
                }, () => {
                    this.five_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((320 / 2915) * 100)
                this.props.showSixQuestion()
                PubSub.publish('changeShowSix', { choice: 'A' })
            }, 1500);
        }
    }
    optionTwo = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(2, 0, 1, 0, 2)
            this.setState({
                five_question_one: "five_question_one animate__animated  animate__fadeOutDown",
                five_question_three: "five_question_three animate__animated  animate__fadeOutDown",
                five_question_four: "five_question_four animate__animated  animate__fadeOutDown",
                choice: 'B',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    five_question_two: "five_question_two animate__animated  animate__fadeOutDown",
                    five_question_title: "five_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_five:"coverPage_five animate__animated  animate__fadeOutDown",
                }, () => {
                    this.five_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((320 / 2915) * 100)
                this.props.showSixQuestion()
                PubSub.publish('changeShowSix', { choice: 'B' })
            }, 1500);
        }
    }
    optionThree = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(1, 4, 0, 0, 0)
            this.setState({
                five_question_two: "five_question_two animate__animated  animate__fadeOutDown",
                five_question_one: "five_question_one animate__animated  animate__fadeOutDown",
                five_question_four: "five_question_four animate__animated  animate__fadeOutDown",
                choice: 'C',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    five_question_three: "five_question_three animate__animated  animate__fadeOutDown",
                    five_question_title: "five_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_five:"coverPage_five animate__animated  animate__fadeOutDown",
                }, () => {
                    this.five_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((320 / 2915) * 100)
                this.props.showSixQuestion()
                PubSub.publish('changeShowSix', { choice: 'C' })
            }, 1500);
        }
    }
    optionFour = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(0, 0, 1, 3, 1)
            this.setState({
                five_question_two: "five_question_two animate__animated  animate__fadeOutDown",
                five_question_three: "five_question_three animate__animated  animate__fadeOutDown",
                five_question_one: "five_question_one animate__animated  animate__fadeOutDown",
                choice: 'D',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    five_question_four: "five_question_four animate__animated  animate__fadeOutDown",
                    five_question_title: "five_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_five:"coverPage_five animate__animated  animate__fadeOutDown",
                }, () => {
                    this.five_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((320 / 2915) * 100)
                this.props.showSixQuestion()
                PubSub.publish('changeShowSix', { choice: 'D' })
            }, 1500);
        }
    }
    backPrevious = () => {
        const { choice } = this.state
        if (choice === 'A') {
            this.props.reduceScore(2, 1, 0, 2, 0)
        } else if (choice === 'B') {
            this.props.reduceScore(1, 3, 1, 0, 0)
        } else if (choice === 'C') {
            this.props.reduceScore(0, 0, 1, 2, 2)
        } else if (choice === 'D') {
            this.props.reduceScore(1, 0, 2, 0, 2)
        } else {
            this.props.reduceScore(0, 0, 0, 0, 0)
        }
        this.props.backPrevious((425 / 2915) * 100)
        this.setState({
            five_question_title: "five_question_title  animate__animated animate__fadeOutDown",
            five_question_one: "five_question_one  animate__animated animate__fadeOutDown",
            five_question_two: "five_question_two  animate__animated animate__fadeOutDown",
            five_question_three: "five_question_three  animate__animated animate__fadeOutDown",
            five_question_four: "five_question_four  animate__animated animate__fadeOutDown",
            // coverPage_five:" coverPage_five animate__animated animate__fadeOutDown"
        },
            () => {
                // this.five_question_title.current.style.display='none'
                this.five_previous_question.current.style.display = 'none'
                // this.five_question_options.current.style.display='none'
            }
        )
        PubSub.publish("changeFourState", {})
        // this.coverPage_five.current.style.display='none'
    }
    componentDidMount() {
        if (base > 750) {
            this.five_question_title.current.style.fontSize = '28px'
        }
        this.token = PubSub.subscribe('changeFiveState', (_, data) => {
            this.setState({
                five_question_title: "five_question_title animate__animated animate__fadeInDown",
                five_question_one: "five_question_one animate__animated animate__fadeInDown",
                five_question_two: "five_question_two animate__animated animate__fadeInDown",
                five_question_three: "five_question_three animate__animated animate__fadeInDown",
                five_question_four: "five_question_four animate__animated animate__fadeInDown",
                // coverPage_five:"coverPage_five animate__animated animate__fadeInDown",
                isClicked: false
            }, () => {
                this.five_previous_question.current.style.display = 'block'
            })
        })
        this.token1 = PubSub.subscribe('showFiveQuestion', (_, data) => {
            // this.coverPage_five.current.style.display='block'
            this.props.showCover(true)
            this.setState({
                five_question_title: "five_question_title animate__animated animate__fadeInDown",
                five_question_one: "five_question_one animate__animated animate__fadeInDown",
                five_question_two: "five_question_two animate__animated animate__fadeInDown",
                five_question_three: "five_question_three animate__animated animate__fadeInDown",
                five_question_four: "five_question_four animate__animated animate__fadeInDown",
                // coverPage_five:"coverPage_five animate__animated  animate__fadeInDown",
                choice: data.choice
            }, () => {
                this.five_previous_question.current.style.display = 'block'
            })
        })
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
        PubSub.unsubscribe(this.token1)
    }
    render() {
        return (
            <div className="five_question"
                style={{ display: this.props.show ? 'block' : 'none' }}>
                {/* <div className={this.state.coverPage_five} ref={this.coverPage_five}></div> */}
                <div className={this.state.five_question_title} ref={this.five_question_title}>
                    <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginRight: '-3px', marginTop: '-5px' }}></img>
                    <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginLeft: '-4px', marginTop: '-5px' }}></img>
                    如果探索已经结束，你可以选择离开了，你想要？
                </div>
                <div className="five_question_options" ref={this.five_question_options}>
                    <QueueAnim
                        key="1"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="five_question_one"
                            className={this.state.five_question_one}
                            onClick={this.optionOne}
                            style={{
                                lineHeight: (80 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                            <span>A.离开，因为该任务已经结束</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="2"
                        delay="1000"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="five_question_two"
                            className={this.state.five_question_two}
                            onClick={this.optionTwo}
                            style={{
                                lineHeight: (80 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                            <span>B.离开，想去探索其他世界</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="3"
                        onEnd={this.onEnd}
                        delay="1300"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="five_question_three"
                            className={this.state.five_question_three}
                            onClick={this.optionThree}
                            style={{
                                lineHeight: (80 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                            <span>C.停留，想看看不一样的风景</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="4"
                        onEnd={this.onEnd}
                        delay="1300"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="five_question_four"
                            className={this.state.five_question_four}
                            onClick={this.optionFour}
                            style={{
                                lineHeight: (80 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                            <span>D.停留，探究如何在不同世界转换</span>
                        </div>
                    </QueueAnim>
                </div>
                <div className="five_previous_question" onClick={this.backPrevious} ref={this.five_previous_question}>
                    上一题
                </div>
            </div>
        )
    }
}
