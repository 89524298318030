import "animate.css"
import Letter from '../Letter'
import Lettertext from '../Lettertext'
import Loading from '../Loading'
import './home.scss';
import React, { PureComponent } from 'react'

export default class home extends PureComponent {
    state ={
        letterTextFlag:true
    }
    changeFlag=(flag)=>{
                console.log(flag)
                this.setState({letterTextFlag:flag},()=>{

                    console.log(this.state.letterTextFlag)
                })



    }
    render() {
        return (
            <div className="home">
                {/* <Music/> */}
                <Loading/>
                <Letter changeFlag={this.changeFlag}/>
                <Lettertext letterTextFlag={this.state.letterTextFlag}/>
            </div>
        )
    }
}

