import React, { Component } from 'react'
import './index.scss'
import QueueAnim from 'rc-queue-anim';
import PubSub from 'pubsub-js';
import Slash from '../../../assets/image/question/Slash.png'
import Frame24 from '../../../assets/image/question/24.png'
import reverseFrame24 from '../../../assets/image/question/reverse24.png'
import Frame23 from '../../../assets/image/question/23.png'
const base = document.documentElement.clientHeight
export default class FourQuestion extends Component {
    state = {
        four_question_title: "four_question_title",
        four_question_one: "four_question_one",
        four_question_two: "four_question_two",
        four_question_three: "four_question_three",
        four_question_four: "four_question_four",
        // coverPage_four:"coverPage_four",
        choice: '',
        isClicked: false
    }
    four_question = React.createRef()
    four_previous_question = React.createRef()
    four_question_title = React.createRef()
    // coverPage_four=React.createRef()
    // four_question_four=React.createRef()
    optionOne = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(2, 1, 0, 2, 0)
            this.setState({
                four_question_two: "four_question_two animate__animated  animate__fadeOutDown",
                four_question_three: "four_question_three animate__animated  animate__fadeOutDown",
                four_question_four: "four_question_four animate__animated  animate__fadeOutDown",
                choice: 'A',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    four_question_one: "four_question_one  animate__animated  animate__fadeOutDown",
                    four_question_title: "four_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_four:"coverPage_four animate__animated  animate__fadeOutDown",
                }, () => {
                    this.four_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((425 / 2915) * 100)
                this.props.showFiveQuestion()
                PubSub.publish("showFiveQuestion", { choice: 'A' })
            }, 1500);
        }
    }
    optionTwo = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(1, 3, 1, 0, 0)
            this.setState({
                four_question_one: "four_question_one animate__animated  animate__fadeOutDown",
                four_question_three: "four_question_three animate__animated  animate__fadeOutDown",
                four_question_four: "four_question_four animate__animated  animate__fadeOutDown",
                choice: "B",
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    four_question_two: "four_question_two  animate__animated  animate__fadeOutDown",
                    four_question_title: "four_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_four:"coverPage_four animate__animated  animate__fadeOutDown",
                }, () => {
                    this.four_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((425 / 2915) * 100)
                this.props.showFiveQuestion()
                PubSub.publish("showFiveQuestion", { choice: "B" })
            }, 1500);
        }
    }
    optionThree = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(0, 0, 1, 2, 2)
            this.setState({
                four_question_two: "four_question_two animate__animated  animate__fadeOutDown",
                four_question_one: "four_question_one animate__animated  animate__fadeOutDown",
                four_question_four: "four_question_four animate__animated  animate__fadeOutDown",
                choice: "C",
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    four_question_three: "four_question_three  animate__animated  animate__fadeOutDown",
                    four_question_title: "four_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_four:"coverPage_four animate__animated  animate__fadeOutDown",
                }, () => {
                    this.four_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((425 / 2915) * 100)
                this.props.showFiveQuestion()
                PubSub.publish("showFiveQuestion", { choice: "C" })
            }, 1500);
        }
    }
    optionFour = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(1, 0, 2, 0, 2)
            this.setState({
                four_question_two: "four_question_two animate__animated  animate__fadeOutDown",
                four_question_three: "four_question_three animate__animated  animate__fadeOutDown",
                four_question_one: "four_question_one animate__animated  animate__fadeOutDown",
                choice: "D",
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    four_question_four: "four_question_four  animate__animated  animate__fadeOutDown",
                    four_question_title: "four_question_title animate__animated  animate__fadeOutDown",
                    // coverPage_four:"coverPage_four animate__animated  animate__fadeOutDown",
                }, () => {
                    this.four_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000);
            setTimeout(() => {
                this.props.nextQuestion((425 / 2915) * 100)
                this.props.showFiveQuestion()
                PubSub.publish("showFiveQuestion", { choice: "D" })
            }, 1500);
        }
    }
    backPrevious = () => {
        // PubSub.publish("changeStateThree",{})
        const { choice } = this.state
        if (choice === 'A') {
            this.props.reduceScore(2, 2, 0, 1, 0)
        } else if (choice === 'B') {
            this.props.reduceScore(0, 0, 2, 1, 2)
        } else {
            this.props.reduceScore(0, 0, 0, 0, 0)
        }
        this.props.backPrevious((480 / 2915) * 100)
        this.setState({
            four_question_title: "four_question_title animate__animated animate__fadeOutDown",
            four_question_one: "four_question_one animate__animated animate__fadeOutDown",
            four_question_two: "four_question_two animate__animated animate__fadeOutDown",
            four_question_three: "four_question_three animate__animated animate__fadeOutDown",
            four_question_four: "four_question_four animate__animated animate__fadeOutDown",
            // coverPage_four:" coverPage_four animate__animated animate__fadeOutDown"
        }, () => {
            // this.four_question.current.style.display='none'
            this.four_previous_question.current.style.display = 'none'
        })
        PubSub.publish("changeStateThree", {})
        // this.coverPage_four.current.style.display='none'
        // this.four_question.current.style.display='none'
        // this.four_previous_question.current.style.display='none'
    }
    componentDidMount() {
        if (base > 750) {
            this.four_question_title.current.style.fontSize = '28px'
        }
        this.token = PubSub.subscribe('changeFourState', (_, data) => {
            this.setState({
                four_question_title: "four_question_title",
                four_question_one: "four_question_one",
                four_question_two: "four_question_two",
                four_question_three: "four_question_three",
                four_question_four: "four_question_four",
                // coverPage_four:"coverPage_four",
                isClicked: false
            }, () => {
                this.four_previous_question.current.style.display = 'block'
            })
        })
        this.token1 = PubSub.subscribe('changeShowFourButton', (_, data) => {
            this.four_question.current.style.display = 'block'
            this.four_previous_question.current.style.display = 'block'
            // this.coverPage_four.current.style.display='block'
            this.props.showCover(true)
            this.setState({
                four_question_title: "four_question_title animate__animated  animate__fadeInDown",
                four_question_one: "four_question_one animate__animated  animate__fadeInDown",
                four_question_two: "four_question_two animate__animated  animate__fadeInDown",
                four_question_three: "four_question_three animate__animated  animate__fadeInDown",
                four_question_four: "four_question_four animate__animated  animate__fadeInDown",
                // coverPage_four:"coverPage_four animate__animated animate__fadeInDown",
                choice: data.choice
            })
        })
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
        PubSub.unsubscribe(this.token1)
    }
    render() {
        return (
            <div className="four_question"
                ref={this.four_question}
                style={{
                    display: this.props.show ? 'block' : 'none',
                    //  top:(2915-base/1.4-base-base/1.4-base/1.7)+'px'
                }}>
                {/* <div className={this.state.coverPage_four} ref={this.coverPage_four}></div> */}
                <div className={this.state.four_question_title} ref={this.four_question_title}>
                    <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginRight: '-3px', marginTop: '-5px' }}></img>
                    <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginLeft: '-4px', marginTop: '-5px' }}></img>
                    <span>完成任务之后会获得一个超能力，你想要？</span>
                </div>
                <div className="four_question_options">
                    <QueueAnim
                        key="1"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="four_question_one"
                            className={this.state.four_question_one}
                            onClick={this.optionOne}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '35vw', height: "40px", position: 'absolute', left: '5px', top: '15px' }}></img>
                            <img src={reverseFrame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px', right: '-11vw' }}></img>
                            <span>A.读心术</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="2"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="four_question_two"
                            className={this.state.four_question_two}
                            onClick={this.optionTwo}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '55vw', height: "40px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px' }}></img>
                            <span>B.比黑洞还大的脑洞</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="3"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="four_question_three"
                            ref={this.four_question_three}
                            className={this.state.four_question_three}
                            onClick={this.optionThree}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '45vw', height: "40px", position: 'absolute', left: '5px', top: '15px' }}></img>
                            <img src={reverseFrame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px', right: '-6vw' }}></img>
                            <span>C.量子波动速读</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="4"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="four_question_four"
                            ref={c => this.four_question_four = c}
                            className={this.state.four_question_four}
                            onClick={this.optionFour}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '70vw', height: "40px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px' }}></img>
                            <span>D.毫无破绽的逻辑思维</span>
                        </div>
                    </QueueAnim>
                </div>
                <div className="four_previous_question" onClick={this.backPrevious} ref={this.four_previous_question}>
                    上一题
                </div>
            </div>
        )
    }
}
