import './App.scss';
import Music from './components/Music';
// import height from './components/style';
import {Redirect, Route,withRouter} from 'react-router-dom'
import Questions from './components/Question/questions';
import Home from './components/Home/home';
import Result from './components/Result';
import TestResult from './components/TestResult/testResult';
import Login from './components/Login'
import Join_us from './components/Join_us'
import AnimatedSwitch from './AnimatedSwitch'
import bigfirefly from '../src/assets/image/homePage/bigfirefly.gif'
import firefly_clear from '../src/assets/image/homePage/firefly_clear.gif'
import firefly_good from '../src/assets/image/homePage/firefly_good.gif'
import firefly_good1 from '../src/assets/image/homePage/firefly_good1.gif'
import Wizard from '../src/assets/image/question/Wizard.png'
import zipFinally from '../src/assets/image/question/zipFinally.jpg'
//result img preload
import fabric from '../src/assets/image/result/fabric.png'
import butterfly from '../src/assets/image/result/butterfly.png'
import small_icon from '../src/assets/image/result/small_icon.png'
import qrCode from '../src/assets/image/result/qrCode.png'
import splitLine from '../src/assets/image/result/splitLine.png'
import triangle from '../src/assets/image/result/triangle.png'
import PM from '../src/assets/image/result/PM.png'
import DESIGN from '../src/assets/image/result/DESIGN.png'
import RD from '../src/assets/image/result/MD.png'
import WEB from '../src/assets/image/result/WEB.png'
import SRE from '../src/assets/image/result/SRE.png'
import default_halo from '../src/assets/image/result/default_halo.png'
import bottom_grass from '../src/assets/image/result/bottom_grass.png'
import bottom_halo from '../src/assets/image/result/bottom_halo.png'
import new_mashroom from '../src/assets/image/result/new_mashroom.png'
import close from '../src/assets/image/result/close.png'
import res_static_firefly from '../src/assets/image/result/res_static_firefly.png'
import rdTitle from '../src/assets/image/result/rdTitle.png'
import pmTitle from '../src/assets/image/result/pmTitle.png'
import desTitle from '../src/assets/image/result/desTitle.png'
import sreTitle from '../src/assets/image/result/sreTitle.png'
import webTitle from '../src/assets/image/result/webTitle.png'
import gradient from '../src/assets/image/result/gradient.png'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
function App() {
  return (
    <div className="App">

      {/* <Music id="music"/> */}
      <img src={bigfirefly} style={{ display: "none" }} alt="bigfirefly "></img>
      <img src={firefly_clear} style={{ display: "none" }} alt="firefly_clear"></img>
      <img src={firefly_good} style={{ display: "none" }} alt="firefly_good"></img>
      <img src={firefly_good1} style={{ display: "none" }} alt="firefly_good1"></img>
      <img src={Wizard} style={{ display: "none" }} alt="Wizard"></img>
      <img src={zipFinally} style={{ display: "none" }} alt="zipFinally"></img>
      <img src={fabric} style={{ display: "none" }} alt="fabric"></img>
      <img src={butterfly} style={{ display: "none" }} alt="butterfly"></img>
      <img src={small_icon} style={{ display: "none" }} alt="small_icon"></img>
      <img src={qrCode} style={{ display: "none" }} alt="qrCode"></img>
      <img src={splitLine} style={{ display: "none" }} alt="splitLine"></img>
      <img src={triangle} style={{ display: "none" }} alt="triangle"></img>
      <img src={PM} style={{ display: "none" }} alt="PM"></img>
      <img src={DESIGN} style={{ display: "none" }} alt="DESIGN"></img>
      <img src={RD} style={{ display: "none" }} alt="RD"></img>
      <img src={WEB} style={{ display: "none" }} alt="WEB"></img>
      <img src={SRE} style={{ display: "none" }} alt="SRE"></img>
      <img src={default_halo} style={{ display: "none" }} alt="default_halo"></img>
      <img src={bottom_grass} style={{ display: "none" }} alt="bottom_grass"></img>
      <img src={bottom_halo} style={{ display: "none" }} alt="bottom_halo"></img>
      <img src={new_mashroom} style={{ display: "none" }} alt="new_mashroom"></img>
      <img src={close} style={{ display: "none" }} alt="close"></img>
      <img src={res_static_firefly} style={{ display: "none" }} alt="res_static_firefly"></img>
      <img src={rdTitle} style={{ display: "none" }} alt="rdTitle"></img>
      <img src={pmTitle} style={{ display: "none" }} alt="pmTitle"></img>
      <img src={desTitle} style={{ display: "none" }} alt="desTitle"></img>
      <img src={sreTitle} style={{ display: "none" }} alt="sreTitle"></img>
      <img src={webTitle} style={{ display: "none" }} alt="webTitle"></img>
      <img src={gradient} style={{ display: "none" }} alt="gradient"></img>




      <Music id="music" />
      <TransitionGroup>
        <CSSTransition
          //in={true}
          classNames="fade"
          timeout={2000}
        >
          <AnimatedSwitch>
            <Route path="/Result" component={Result}></Route>
            <Route path="/Questions" component={Questions}></Route>
            <Route path="/Home" component={Home}></Route>
            <Route path="/TestResult" component={TestResult}></Route>
            <Route path="/Login" component={Login}></Route>
            <Route path="/Join_us" component={Join_us}></Route>
            <Redirect to="/Home"></Redirect>
          </AnimatedSwitch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default withRouter(App);
