import React, { Component } from 'react'
import './index.scss'
import QueueAnim from 'rc-queue-anim';
import PubSub from 'pubsub-js';
import Slash from '../../../assets/image/question/Slash.png'
import Frame24 from '../../../assets/image/question/24.png'
import reverseFrame24 from '../../../assets/image/question/reverse24.png'
import Frame23 from '../../../assets/image/question/23.png'
import box_open3 from '../../../assets/image/question/box_open3.gif'
import static_box from '../../../assets/image/question/static_black.png'

import { withRouter } from 'react-router-dom'
const base = document.documentElement.clientHeight
class SixQuestion extends Component {
    state = {
        six_question_title_hint: "six_question_title_hint",
        six_question_title_subject: "six_question_title_subject",
        six_question_one: "six_question_one",
        six_question_two: "six_question_two",
        six_question_three: "six_question_three",
        // coverPage_six:"coverPage_six",
        choice: '',
        isClicked: false
    }
    six_question_title_hint = React.createRef()
    six_question = React.createRef()
    showbox = React.createRef()
    // six_question_title_hint=React.createRef()
    six_question_title_subject = React.createRef()
    six_question_options = React.createRef()
    six_previous_question = React.createRef()
    static_box=React.createRef()
    optionOne = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(2, 3, 0, 0, 0)
            this.setState({
                six_question_three: "six_question_three animate__animated  animate__fadeOutDown",
                six_question_two: "six_question_two animate__animated  animate__fadeOutDown",
                choice: 'A',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    six_question_one: "six_question_one animate__animated  animate__fadeOutDown",
                    six_question_title_subject: "six_question_title_subject animate__animated  animate__fadeOutDown",
                    // coverPage_six:"coverPage_six animate__animated  animate__fadeOutDown",
                }, () => {
                    this.six_previous_question.current.style.display = 'none'
                    this.static_box.current.style.display='none'
                    this.showbox.current.style.display = 'block'
                })
                this.props.showCover(false)
            }, 1500);
            setTimeout(() => {
                const result = this.props.sendALLScore()
                localStorage.setItem('result', JSON.stringify(result))
                this.props.history.push('/Login')
            }, 3500);
        }
    }
    optionTwo = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(1, 0, 1, 2, 1)
            this.setState({
                six_question_one: "six_question_one animate__animated  animate__fadeOutDown",
                six_question_three: "six_question_three animate__animated  animate__fadeOutDown",
                choice: 'B',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    six_question_two: "six_question_two animate__animated  animate__fadeOutDown",
                    six_question_title_subject: "six_question_title_subject animate__animated  animate__fadeOutDown",
                    // coverPage_six:"coverPage_six animate__animated  animate__fadeOutDown",
                }, () => {
                    this.six_previous_question.current.style.display = 'none'
                    this.static_box.current.style.display='none'
                    this.showbox.current.style.display = 'block'
                })
                this.props.showCover(false)
            }, 1500);
            setTimeout(() => {
                const result = this.props.sendALLScore()
                localStorage.setItem('result', JSON.stringify(result))
                this.props.history.push('/Login')
            }, 3500)
        }
    }
    optionThree = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(0, 0, 2, 1, 2)
            this.setState({
                six_question_two: "six_question_two animate__animated  animate__fadeOutDown",
                six_question_one: "six_question_one animate__animated  animate__fadeOutDown",
                choice: 'C',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    six_question_three: "six_question_three animate__animated  animate__fadeOutDown",
                    six_question_title_subject: "six_question_title_subject animate__animated  animate__fadeOutDown",
                    // coverPage_six:"coverPage_six animate__animated  animate__fadeOutDown",
                }, () => {
                    this.six_previous_question.current.style.display = 'none'
                    this.static_box.current.style.display='none'
                    this.showbox.current.style.display = 'block'
                })
                this.props.showCover(false)
            }, 1500);
            setTimeout(() => {
                const result = this.props.sendALLScore()
                localStorage.setItem('result', JSON.stringify(result))
                this.props.history.push('/Login')
            }, 3500);
        }
    }
    backPrevious = () => {
        const { choice } = this.state
        if (choice === 'A') {
            this.props.reduceScore(1, 0, 2, 1, 1)
        } else if (choice === 'B') {
            this.props.reduceScore(2, 0, 1, 0, 2)
        } else if (choice === 'C') {
            this.props.reduceScore(1, 4, 0, 0, 0)
        } else if (choice === 'D') {
            this.props.reduceScore(0, 0, 1, 3, 1)
        } else {
            this.props.reduceScore(0, 0, 0, 0, 0)
        }
        this.props.backPrevious((320 / 2915) * 100)
        this.setState({
            six_question_title_subject: "six_question_title_subject  animate__animated  animate__fadeOutDown",
            six_question_one: "six_question_one  animate__animated  animate__fadeOutDown",
            six_question_two: "six_question_two  animate__animated  animate__fadeOutDown",
            six_question_three: "six_question_three  animate__animated  animate__fadeOutDown",
        }, () => {
            this.six_question.current.style.display = 'none'
            this.six_previous_question.current.style.display = 'none'
        })
        PubSub.publish('changeFiveState', {})
    }
    componentDidMount() {
        this.token = PubSub.subscribe('changeShowSix', (_, data) => {
            this.six_question_title_hint.current.style.display = 'block'
            this.props.showCover(true)
            setTimeout(() => {
                this.six_question_title_hint.current.style.display = 'none'
                this.six_question.current.style.display = 'block'
                this.six_previous_question.current.style.display = 'block'
                this.six_question_options.current.style.display = 'block'
                this.six_question_title_subject.current.style.display = 'block'
                this.six_question_title_hint.current.style.display = 'none'
            }, 3500)
            this.setState({
                six_question_title_subject: "six_question_title_subject  animate__animated  animate__fadeInDown ",
                six_question_one: "six_question_one  animate__animated  animate__fadeInDown",
                six_question_two: "six_question_two  animate__animated animate__fadeInDown",
                six_question_three: "six_question_three  animate__animated  animate__fadeInDown",
                // coverPage_six:"coverPage_six animate__animated animate__fadeInDown",
                choice: data.choice
            }, () => {
                this.six_previous_question.current.style.display = 'block'
            })

        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
    }
    render() {
        return (
            <div className="six_question" ref={this.six_question} style={{ display: this.props.show ? 'block' : "none" }}>
          
                <div className="showbox" style={{ display: "none" }} ref={this.showbox}>
                    <img src={box_open3} alt="err"></img>
                </div>
                <div className="static_box">
                    <img src={static_box} alt="err" ref={this.static_box}></img>
                </div>
                <div className="six_question_title">
                    <div className={this.state.six_question_title_hint} ref={this.six_question_title_hint} style={{ display: 'block' }}>
                        前方石墩上的木盒里装有有来自红岩的邀请函和一幅画
                    </div>
                    <div className={this.state.six_question_title_subject} style={{ display: "none" }} ref={this.six_question_title_subject}>
                        <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginRight: '-3px', marginTop: '-5px' }}></img>
                        <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginLeft: '-4px', marginTop: '-5px' }}></img>
                        <span>木盒里的那幅画，你希望它的内容是什么？</span>
                    </div>
                </div>
                <div className="six_question_options" ref={this.six_question_options} style={{ display: "none" }} >
                    <QueueAnim
                        key="1"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="six_question_one"
                            className={this.state.six_question_one}
                            onClick={this.optionOne}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '55vw', height: "40px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px' }}></img>
                            <span>A.重复有规律的图案</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="2"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="six_question_two"
                            className={this.state.six_question_two}
                            onClick={this.optionTwo}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '45vw', height: "40px", position: 'absolute', left: '5px', top: '15px' }}></img>
                            <img src={reverseFrame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px', right: '-25px' }}></img>
                            <span>B.浪漫夏日星空</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="3"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="six_question_three"
                            className={this.state.six_question_three}
                            onClick={this.optionThree}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '60vw', height: "40px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px' }}></img>
                            <span>C.未来的科技感的世界</span>
                        </div>
                    </QueueAnim>
                </div>
                <div className="six_previous_question" onClick={this.backPrevious} ref={this.six_previous_question}>上一题</div>
            </div>
        )
    }
}
export default withRouter(SixQuestion)