import React, { Fragment, PureComponent } from 'react'
import grass from '../../assets/image/homePage/grass_nofirefly.png'
import halo from '../../assets/image/homePage/texthalo.png'
import butterfly from '../../assets/image/homePage/butterfly.png'
import fabric from '../../assets/image/homePage//fabric.png'
import bigfirefly from '../../assets/image/homePage/firefly_good.gif'
import bigfirefly1 from '../../assets/image/homePage/firefly_good1.gif'
// import opacityFirefly from '../../assets/image/homePage/opacityFirefly.gif'
import right_opacity_firefly from '../../assets/image/homePage/right_opacity_firefly.gif'
import {Link} from 'react-router-dom'
import './index.scss'
export default class index extends PureComponent {
    state={
        flag:true,
        style:{display:'none'},
        maskStyle:{},
        count:0,
        className:'mask',
        grassStyle:{display:'none'},
        height:330,  //100
        titleContent:"",
        textContent:"",
        textNewContent:'',
        titleID:'letterTextTitle',
        contentID:'',
        buttonStyle:{display:'none'},
        buttonClassname:'bottomButton',
        fireflyStyle:{},
        fireflyStyle2:{},
        contentID2:'',
        closePage:{}

    }
    setTitle = (content,time)=>{
            return new Promise(resolve=>{
                setTimeout(() => {
                    this.setState({titleContent:content})
                        resolve()
                }, time);
            })
    }
    setText = (content,time)=>{
        return new Promise(resolve=>{
            setTimeout(() => {
                this.setState({textContent:content})
                    resolve()
            }, time);
        })
    }
    setText2 = (content,time)=>{
        return new Promise(resolve=>{
            setTimeout(() => {
                this.setState({textContent:content})
                    resolve()
            }, time);
        })
    }
    setText3 = (content,time)=>{
        return new Promise(resolve=>{
            setTimeout(() => {
                this.setState({textNewContent:content})
                    resolve()
            }, time);
        })
    }
    componentDidMount(){
        
    }
    componentDidUpdate(){
        /**
         * 立即查看点击事件触发后state改变，接到标识符
         */
        // const {height,fireflyStyle,textNewContent,contentID2} = this.state
        this.setState({flag:this.props.letterTextFlag})
        if(this.state.flag===false&&this.state.count===0){

            this.setState({count:1})
            setTimeout(()=>{
                this.setState({className:"mask animate__animated animate__fadeOut animate__slow"})
                console.log(111)
            },200)
            //遮音乐的罩子
            setTimeout(()=>{
                this.setState({maskStyle:{display:'none'}})
            },1000)
            //展示小草
            setTimeout(()=>{
                this.setState({grassStyle:{}})
            },1000)
            setTimeout(
                async() => {
                    let title = "Hi，萌新:";
                    for(let i = 1;i<title.length+1;i++){
                        await this.setTitle(title.substr(0,i),200)
                    }
            }, 2300);
            setTimeout(
                () => {
                    this.setState({titleID:''})
            }, 4000);
            setTimeout(
                async() => {
                    let title = "这是一封来自红岩网校工作站的入学邀请，无论你是否听说过我们的名字，我们都诚挚的邀请你加入红岩网校工作站";
                    this.setState({fireflyStyle:{display:'none'}})
                    for(let i = 1;i<title.length+1;i++){
                        await this.setText(title.substr(0,i),100)
                    }
            }, 4200);
            setTimeout(() => {
                this.setState({contentID:"letterTextContent"})
            }, 4500);
            setTimeout(() => {
                this.setState({fireflyStyle2:{display:'none'}})
            }, 7500);
            setTimeout(
                async() => {
                    let title = "现在，来完成你在网校的第一个任务";
                    for(let i = 1;i<title.length+1;i++){
                        await this.setText2(title.substr(0,i),100)
                    }
            }, 10000);
            setTimeout(
                async() => {
                    this.setState({contentID:''})
                    this.setState({contentID2:'letterTextContent'})
                    let title = "进入虚拟世界，来一场冒险吧";
                    for(let i = 1;i<title.length+1;i++){
                        await this.setText3(title.substr(0,i),100)
                    }
            }, 11700);
            setTimeout(()=>{
                this.setState({buttonStyle:{}})
                this.setState({buttonClassname:'bottomButton animate__animated animate__fadeInUp'})

            },13500)
        }
    }
    start_adventure=()=>{
        this.setState({closePage:{display:'none'}})
    }
    render() {
        const {className,grassStyle,maskStyle,titleContent,titleID,textContent,contentID,buttonStyle,buttonClassname,fireflyStyle,fireflyStyle2,textNewContent,contentID2,closePage} = this.state
        return (
            <Fragment>
                <div style={{closePage}}>
                <div style={grassStyle}>
                    <div className="LetterText_empty"></div>
                {/* <div className='haloMask' style={{height:`${height}px`}}> */}
                <div className='haloMask'>
                    <div className="haloMaskTop"></div>
                    <div className="haloMaskBottom"></div>
                </div>
                {/* <div className='haloMask'>
                    <img src={opacityMask} alt="err" id="opacityMask" style={{height:`${height}px`}}/>
                </div> */}
                </div>
                <div className={className} style={maskStyle}></div>
                <div className="LettertextOuter" style={grassStyle}>
                    <div className="Lettercontent" style={grassStyle}>
                    {/* <div className="Lettercontent" style={grassStyle} style={{clipPath:`inset(${height}% 0 0 0)`}}> */}
                        <div className="letterText">
                            <div className="letterTextTitle" id={titleID}>{titleContent}</div>
                            <div className="letterTextContent"><div id={contentID}>{textContent}</div><div id={contentID2}>{textNewContent}</div></div>
                        </div>
                        <img src={bigfirefly} alt="err" id="bigfirefly1" style={fireflyStyle}/>
                        <img src={bigfirefly1} alt="err" id="bigfirefly2" style={fireflyStyle}/>
                        <img src={bigfirefly1} alt="err" id="bigfirefly3" style={fireflyStyle2}/>
                        <img src={bigfirefly} alt="err" id="bigfirefly4" style={fireflyStyle2}/>
                        <img src={bigfirefly1} alt="err" id="bigfirefly5"/>
                        <img src={right_opacity_firefly} alt="err" id="right_opacity_firefly"/>
                        <img src={right_opacity_firefly} alt="err" id="right_opacity_firefly1"/>
                        <img src={right_opacity_firefly} alt="err" id="right_opacity_firefly2"/>
                        <img src={halo} alt="err" className='halo'/>
                        <img src={grass} alt="err" className="grass"/>
                    <Link replace to="/Questions" id="Link">
                        <div className={buttonClassname} style={buttonStyle}>
                            <img src={butterfly} alt="err" id="butterfly"/>
                            <span id="start_adventure" onClick={this.start_adventure}>开始冒险</span>
                            <img src={fabric} alt="err"/>
                            <div className="slash1"></div>
                            <div className="slash2"></div>
                            {/* <img src={slash} alt="err" id="slash"/>
                            <img src={slash} alt="err" id="slash"/> */}
                        </div>
                    </Link>
                    </div>
                </div>
                </div>
            </Fragment>
        )
    }
}
