import React, { Component } from 'react';
// import YouSheBiaoTi from '../../assets/fonts/YouSheBiaoTiHei-2.ttf'
import ReactEcharts from 'echarts-for-react';


export default class chart extends Component {

    getOption = ()=>{
        let option = {
            legend: {
                itemWidth: 10,
                itemHeight: 10,
            },
            radar: [
                {
                    indicator: [
                        { text: 'Design',max:35 },
                        { text: 'WEB',max:35 },
                        { text: 'Mobile',max:35 },
                        { text: 'SRE',max:35 },
                        { text: 'PM',max:35 }
                    ],
                    textStyle: {
                        color:''
                    },
                    //控制雷达图位置
                    center: ['52.3%', '47%'],
                    //控制雷达图大小
                    radius: 59,
                    // name:{
                    //     textStyle:{
                    //         fontSize:16
                    //     }
                    // },
                    startAngle: 90,
                    splitNumber: 2,
                    // 控制字离图的间距
                    axisNameGap :7,
                    shape: 'circle',
                    point:null,
                    axisName: {
                        // 文字的颜色
                        color: '#37A2B1',  //79FFFF
                        margin:'0px',
                        fontSize:'12px',
                    },
                    splitArea: {
                        areaStyle: {
                            color: ['transparent'],
                            shadowColor: '#79FFFF',
                            shadowBlur: 10
                        }
                    },
                    axisLine: {
                        // 中间纵线
                        lineStyle: {
                            color: '#37A2B1'
                        }
                    },
                    splitLine: {
                        // 中间圆圈
                        lineStyle: {
                            color: '#37A2B1'
                        }
                    },
                    
                }
            ],
            series: [
                {
                    name: '雷达图',
                    type: 'radar',
                    symbol: 'circle', 
                    symbolSize: 0, 
                    emphasis: {
                        lineStyle: {
                            width: 4
                        }
                    },
                    data: [
                        {
                            //1pm 2web 3mob 4sre 5pm
                            value: [80*this.props.desPercent, 80*this.props.webPercent, 80*this.props.rdPercent, 80*this.props.srePercent, 80*this.props.pmPercent],
                            // 中间覆盖面积边界线条的颜色
                            itemStyle: {
                                color: 'transparent',
                            },
                            //中间覆盖面积的颜色
                            areaStyle: {
                                color: '#79FFFF'//#37A2B1
                            },
                            
                        }
                    ],
                    line: {
                        type: null
                    },
                },
            ]
        };
        return option;
    };
    render() {
        // console.log(60*this.props.desPercent)
        // console.log(this.props.rdPercent,this.props.desPercent,this.props.webPercent,this.props.srePercent,this.props.pmPercent)
        return (
            <ReactEcharts option={this.getOption()}/>
        )
    }
}

