import React, { Component,Fragment  } from 'react'
import QueueAnim from 'rc-queue-anim';
import './index.scss'
import Slash from '../../../assets/image/question/Slash.png'
import Frame24 from '../../../assets/image/question/24.png'
import 'animate.css'
import Frame23 from '../../../assets/image/question/23.png'
import TwoQuestion from '../twoQuestion';
import ThreeQuestion from '../threeQuestion';
import FourQuestion from '../fourQuestion';
import FiveQuestion from '../fiveQuestion';
import SixQuestion from '../sixQuestion';
import PubSub from 'pubsub-js';
// import { Fragment } from 'react';
const base = document.documentElement.clientHeight
var distance = 0
// const root = document.documentElement
// root.style.setProperty('--base',base)



export default class QuestionPage extends Component {
    state = {
        flag:false,
        TranslateY: 0,
        coverPage: "coverPage",
        first_question: 'first_question',
        question_page: "question_page",
        first_question_title: "first_question_title animate__animated animate__fadeInDown",
        first_question_title_copy: "first_question_title_copy animate__animated animate__fadeInDown",
        firstContentClass: 'first_question_contentOne',
        firstContentClass_copy: 'first_question_contentOne_copy',
        twoContentClass: 'first_question_contentTwo',
        twoContentClass_copy: 'first_question_contentTwo_copy',
        threeContentClass: 'first_question_contentThree',
        threeContentClass_copy: "first_question_contentThree_copy",
        TwoQuestionShow: false,
        ThreeQuestionShow: false,
        FourQuestionShow: false,
        FiveQuestionShow: false,
        SixQuestionShow: false,
        uiScore: 0,
        pmScore: 0,
        sreScore: 0,
        rdScore: 0,
        webScore: 0,
        isClicked: false,
        isShow: true,
        base: base
    }
    question_page = React.createRef()
    first_question = React.createRef()
    coverPage=React.createRef()

    //控制第一题在屏幕中间的位置
    positionQuestion=()=>{
        const width = document.documentElement.clientWidth
        const height = document.documentElement.clientHeight
        const ratio = width / height
        // alert(`ratio${ratio}` ) q
        // console.log("ratio" + ratio);
        if (ratio >= 0.69) {
            this.first_question.current.style.top = `${2800 / 610 * height}px`
        } else if (ratio >= 0.68) {
            this.first_question.current.style.top = `${2700 / 610 * height}px`
        }
        else if (ratio >= 0.63) {
            this.first_question.current.style.top = `${2500 / 610 * height}px`
        }
        else if (ratio >= 0.62) {
            this.first_question.current.style.top = `${2450 / 610 * height}px`
        }
        else if (ratio >= 0.61) {
            this.first_question.current.style.top = `${2400 / 610 * height}px`
        }
        else if (ratio >= 0.59) {
            this.first_question.current.style.top = `${2550 / 667 * height}px`
        }
        else if (ratio >= 0.57) {
            this.first_question.current.style.top = `${2500 / 667 * height}px`
        }
        else if (ratio >= 0.5625) {
            this.first_question.current.style.top = `${2400 / 667 * height}px`
        }
        else if (ratio >= 0.5622) {
            console.log("top" + 0.56);
            this.first_question.current.style.top = `${2443 / 667 * height}px`
        }
        else if (ratio >= 0.56) {
            console.log("top" + 0.56);
            this.first_question.current.style.top = `${2443 / 667 * height}px`
        }
        else if (ratio >= 0.54) {
            console.log("top" + 0.56);
            this.first_question.current.style.top = `${2300 / 667 * height}px`
        }
        else if (ratio >= 0.538) {
            console.log("top" + 0.56);
            this.first_question.current.style.top = `${2350 / 667 * height}px`
        }
        else if (ratio >= 0.52) {
            console.log("top" + 0.5);
            this.first_question.current.style.top = `${2250 / 667 * height}px`
        }
        else if (ratio >= 0.51) {
            console.log("top" + 0.5);
            this.first_question.current.style.top = `${2150 / 667 * height}px`
        }
        else if (ratio >= 0.507) {
            console.log("top" + 0.5);
            this.first_question.current.style.top = `${2150 / 667 * height}px`
        }
        else if (ratio >= 0.506) {
            console.log("top" + 0.5);
            this.first_question.current.style.top = `${2100 / 667 * height}px`
        }
        else if (ratio >= 0.5) {
            console.log("top" + 0.5);
            this.first_question.current.style.top = `${2500 / 667 * height * 0.85}px`
        }
        else if (ratio >= 0.499) {
            this.first_question.current.style.top = `${2300 / 812 * height * 1.05}px`
        }
        else if (ratio >= 0.49) {
            this.first_question.current.style.top = `${2400 / 812 * height * 1.05}px`
        }
        else if (ratio >= 0.481) {
            this.first_question.current.style.top = `${2300 / 812 * height * 1.05}px`
        }
        else if (ratio >= 0.47) {
            this.first_question.current.style.top = `${2400 / 812 * height}px`
        }
        else if (ratio >= 0.461) {
            this.first_question.current.style.top = `${2350 / 812 * height}px`
        }
        else if (ratio >= 0.45) {
            this.first_question.current.style.top = `${2200 / 812 * height * 1.05}px`
        } else {
            this.first_question.current.style.top = `${2100 / 812 * height * 1.05}px`
        }
    }



    componentDidMount() {
        this.positionQuestion()
        document.body.style.overflow = 'hidden'
        console.log('web:' + this.state.webScore);
        console.log('ui:' + this.state.uiScore);
        console.log('sre:' + this.state.sreScore);
        console.log('pm:' + this.state.pmScore);
        console.log('rd:' + this.state.rdScore);
    }
    upDistance=()=>{
        if (base > 900) {
            distance = (470 / 2915) * 100
            }
            else if (base >= 880) {
                distance = (300 / 2915) * 100
           }
           else if (base >= 840) {
            distance = (300 / 2915) * 100
       }
            else if (base >= 833) {
                 distance = (370 / 2915) * 100
            }
            else if (base >= 823) {
                 distance = (410 / 2915) * 100
            }
            else if (base >= 812) {
                 distance = (300 / 2915) * 100
            }
            else if (base >= 791) {
                distance = (387 / 2915) * 100
            }
            else if (base >= 790) {
                  distance = (425 / 2915) * 100
            }
            else if (base >= 780) {
                distance = (410 / 2915) * 100
            }
            else if (base > 750) {
                 distance = (400 / 2915) * 100
            }
            else if (base >= 736) {
                // distance=(395/2915)*100
               distance = (480 / 2915) * 100
            }
            else if (base >= 730) {
                distance = (347 / 2915) * 100
            }
            else if (base >= 723) {
                 distance = (447 / 2915) * 100
            }
            else if (base >= 715) {
                distance = (350 / 2915) * 100
            }
            else if (base >= 711) {
                //zhige
                distance = (380 / 2915) * 100
            }
            else if (base >= 700) {
                distance = (394 / 2915) * 100
            }
            else if (base >= 674) {
                 distance = (440 / 2915) * 100
            }
            else if (base >= 667) {
                 distance = (475 / 2915) * 100
            }
            else if (base >= 658) {
                   distance = (475 / 2915) * 100
            }
            else if (base >= 568) {
               distance = (515 / 2915) * 100
            }
    }

    
    fade1 = () => {
        if (this.state.isClicked !== true) {
            // const height = this.question_page.current
            PubSub.publish('unshowCover',{})
            this.addScore(2, 3, 0, 0, 0)
            this.upDistance()
            this.setState({
                twoContentClass: 'first_question_contentTwo animate__animated animate__fadeOutDown',
                threeContentClass: 'first_question_contentThree animate__animated animate__fadeOutDown',
                isClicked: false,
            })
            setTimeout(() => {
                this.setState({
                    firstContentClass: 'first_question_contentOne animate__animated animate__fadeOutDown',
                    first_question_title: "first_question_title animate__animated  animate__fadeOutDown",
                    //coverPage:"coverPage animate__animated  animate__fadeOutDown",
                    coverPage: "coverPage  coverDisappear",
                    TranslateY: distance,
                    TwoQuestionShow: true,
                }, () => {
                    PubSub.publish('changeShowTwo', {})
                })

            }, 500)

        }
    }
    fade2 = () => {
        if (this.state.isClicked !== true) {
            PubSub.publish('unshowCover',{})
            this.addScore(1, 0, 0, 3, 1)
            this.upDistance()
            this.setState({
                firstContentClass: 'first_question_contentOne animate__animated animate__fadeOutDown',
                threeContentClass: 'first_question_contentThree animate__animated animate__fadeOutDown',
                isClicked: false
            })

            setTimeout(() => {
                this.setState({
                    twoContentClass: 'first_question_contentTwo animate__animated animate__fadeOutDown',
                    first_question_title: "first_question_title animate__animated animate__fadeOutDown",
                    // coverPage:"coverPage animate__animated  animate__fadeOutDown" ,
                    coverPage: "coverPage  coverDisappear",
                    question_page: "question_page question_page_firstUp",
                    TranslateY:  distance,
                    TwoQuestionShow: true
                }, () => {
                    PubSub.publish('changeShowTwo', {})
                })
            }, 500)
        }
    }
    fade3 = () => {
        if (this.state.isClicked !== true) {
            PubSub.publish('unshowCover',{})
            this.addScore(0, 0, 3, 0, 2)
            this.upDistance()
            this.setState({
                firstContentClass: 'first_question_contentOne animate__animated animate__fadeOutDown',
                twoContentClass: 'first_question_contentTwo animate__animated animate__fadeOutDown',
                isClicked: false
            })

            setTimeout(() => {
            
                this.setState({
                    threeContentClass: 'first_question_contentThree animate__animated animate__fadeOutDown',
                    first_question_title: "first_question_title animate__animated  animate__fadeOutDown",
                    // coverPage:"coverPage animate__animated animate__fadeOutDown" ,
                    coverPage: "coverPage  coverDisappear",
                    question_page: "question_page question_page_firstUp",
                    TranslateY: distance,
                    TwoQuestionShow: true
                }, () => {
                    PubSub.publish('changeShowTwo', {})
                })
            }, 500)
        }
    }
    //点击选项为不同部门加分
    addScore = (pm, ui, web, sre, rd) => {
        this.setState({
            uiScore: this.state.uiScore + ui * 1,
            webScore: this.state.webScore + web * 1,
            pmScore: this.state.pmScore + pm * 1,
            sreScore: this.state.sreScore + sre * 1,
            rdScore: this.state.rdScore + rd * 1,
        }, () => {
            console.log('web:' + this.state.webScore + '加了多少分' + web);
            console.log('ui:' + this.state.uiScore + '加了多少分' + ui);
            console.log('sre:' + this.state.sreScore + '加了多少分' + sre);
            console.log('pm:' + this.state.pmScore + '加了多少分' + pm);
            console.log('rd:' + this.state.rdScore + '加了多少分' + rd);
        })
    }
    //点击上一题消除刚才选的分，重新计算
    reduceScore = (pm, ui, web, sre, rd) => {
        this.setState({
            uiScore: this.state.uiScore - ui * 1,
            webScore: this.state.webScore - web * 1,
            pmScore: this.state.pmScore - pm * 1,
            sreScore: this.state.sreScore - sre * 1,
            rdScore: this.state.rdScore - rd * 1,
        }, () => {
            console.log('web:' + this.state.webScore + '减了多少分' + web);
            console.log('ui:' + this.state.uiScore + '减了多少分' + ui);
            console.log('sre:' + this.state.sreScore + '减了多少分' + sre);
            console.log('pm:' + this.state.pmScore + '减了多少分' + pm);
            console.log('rd:' + this.state.rdScore + '减了多少分' + rd);
        })
    }
    //点击问题显示和隐藏遮盖层
    showCover = (flag) => {
        if (flag === true) {
            this.setState({
                coverPage: 'coverPage coverShow',

            })
        } else {
            this.setState({
                coverPage: 'coverPage coverDisappear',
                // isShow:true
            })
        }
    }
    backPrevious = (distance) => {
        this.setState({ TranslateY: this.state.TranslateY - distance * 1 })
    }

    //返回第一题修改第一题的show状态
    changeShow = () => {
        console.log(2);
        this.setState({
            TranslateY: 0,
            coverPage: "coverPage",
            first_question: 'first_question',
            question_page: "question_page",
            first_question_title: "first_question_title animate__animated animate__fadeInDown",
            firstContentClass: 'first_question_contentOne',
            twoContentClass: 'first_question_contentTwo',
            threeContentClass: 'first_question_contentThree',
            first_question_title_copy: "first_question_title_copy animate__animated animate__fadeInDown",
            firstContentClass_copy: "first_question_contentOne_copy",
            twoContentClass_copy: 'first_question_contentTwo_copy',
            threeContentClass_copy: 'first_question_contentThree_copy',
            TwoQuestionShow: false,
            ThreeQuestionShow: false,
            uiScore: 0,
            pmScore: 0,
            sreScore: 0,
            rdScore: 0,
            webScore: 0,

        })
    }


    //第二题选择进入下一题
    nextQuestion = (value) => {
        this.setState({ TranslateY: this.state.TranslateY + value * 1 })
    }


    //点击第二题，第三题出现
    showThreeQuestion = () => {
        this.setState({ ThreeQuestionShow: true, })
    }
    //点击第三题，第四题再出现
    showFourQuestion = () => {
        this.setState({ FourQuestionShow: true, })
    }
    //点击第四题，第五题出现
    showFiveQuestion = () => {
        this.setState({ FiveQuestionShow: true, })
    }
    //点击第五题，第六题出现
    showSixQuestion = () => {
        this.setState({ SixQuestionShow: true, }, () => {
            console.log(1231241413);
        })
    }
  
    //总的分数
    sendALLScore = () => {
        return [
            { name: '产品策划及运营部', score: this.state.pmScore },
            { name: '视觉设计部', score: this.state.uiScore },
            { name: 'WEB研发部', score: this.state.webScore },
            { name: '运维安全部', score: this.state.sreScore },
            { name: '移动开发部', score: this.state.rdScore },
        ]
    }
    //控制音乐组件播放功能
    changeMusic=()=>{
        if(this.state.flag){
            
            localStorage.removeItem('play')
            localStorage.setItem('play',true)
           this.setState({flag:!this.state.flag})
           PubSub.publish('play',{flag:true})
        }else{
            localStorage.removeItem('play')
            localStorage.setItem('play',false)
            this.setState({flag:!this.state.flag})
            PubSub.publish('play',{flag:false})
        }
    }

    //第二题改变蒙版在题目的下面
    // changeCover=(flag)=>{
    //     if(flag===true){
    //         this.coverPage.current.style.display='block'
    //         // this.setState({coverPage:"coverPage coverShow"})
    //         // console.log(11111111);
    //     }else{
    //         // this.setState({coverPage:"coverPage coverDisappear"})
    //         this.coverPage.current.style.display='none'
    //     }
    // }
    render() {
        return (
            <Fragment>

             <div className="changeMusic" onClick={this.changeMusic}></div>
            <div className={this.state.question_page}
                style={{
                    display: this.props.show ? 'block' : 'none',
                    transform: `translateY(${this.state.TranslateY}%)`, transition: '3s',
                    // top:(-2915+base/1.7)+'px'
                }} ref={this.question_page}>
                <div className={this.state.coverPage} ref={this.coverPage}></div>
                {/* <div className="changeMusic"></div> */}
                <div
                    className={this.state.first_question}
                    ref={this.first_question}
                >
                    <div className={this.state.first_question_title} >
                        <img src={Slash} alt="404" style={{ width: '18px', height: '23px', marginRight: '-3px', marginTop: '-5px' }}></img>
                        <img src={Slash} alt="404" style={{ width: '18px', height: '23px', marginLeft: '-4px', marginTop: '-5px' }}></img>
                        刚进入游戏时，你首先会？
                    </div>

                    {this.props.show ? [
                        <div key="alone" >
                            <QueueAnim
                                key="1"
                                delay="700"
                                animConfig={[
                                    { opacity: [1, 0], translateY: [0, 800] }
                                ]}>
                                <div key="first_question_one"
                                    className={this.state.firstContentClass}
                                    onClick={this.fade1}
                                    style={{
                                        lineHeight: (80 / base) * 100 + 'vh',

                                    }}>
                                    <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                    <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                    <span>A.观察周围环境</span>
                                </div>
                            </QueueAnim>
                            <QueueAnim
                                key="2"
                                delay="1000"
                                animConfig={[
                                    { opacity: [1, 0], translateY: [20, 800] }
                                ]}>
                                <div key="first_question_two"
                                    style={{
                                        lineHeight: (80 / base) * 100 + 'vh',

                                    }}
                                    className={this.state.twoContentClass}
                                    onClick={this.fade2}>
                                    <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                    <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                    <span>B.思考如何通过测试</span>
                                </div>
                            </QueueAnim>
                            <QueueAnim
                                key="3"
                                onEnd={this.onEnd}
                                delay="1300"
                                animConfig={[
                                    { opacity: [1, 0], translateY: [20, 800] }
                                ]}>
                                <div key="first_question_three" style={{
                                    lineHeight: (80 / base) * 100 + 'vh',

                                }}
                                    className={this.state.threeContentClass}
                                    onClick={this.fade3}>
                                    <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                    <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                    <span>C.思考是什么技术带我进入游戏</span>
                                </div>
                            </QueueAnim>
                        </div>
                    ] : null}
                </div>

                <TwoQuestion show={this.state.TwoQuestionShow}
                    callback={this.addScore}
                    backPrevious={this.backPrevious}
                    changeShow={this.changeShow}
                    nextQuestion={this.nextQuestion}
                    showThreeQuestion={this.showThreeQuestion}
                    reduceScore={this.reduceScore}
                    showCover={this.showCover}
                    // changeCover={this.changeCover}
                />
                <ThreeQuestion
                    addScore={this.addScore}
                    backPrevious={this.backPrevious}
                    show={this.state.ThreeQuestionShow}
                    nextQuestion={this.nextQuestion}
                    showFourQuestion={this.showFourQuestion}
                    reduceScore={this.reduceScore}
                    showCover={this.showCover}
                />
                <FourQuestion
                    addScore={this.addScore}
                    backPrevious={this.backPrevious}
                    show={this.state.FourQuestionShow}
                    nextQuestion={this.nextQuestion}
                    showFiveQuestion={this.showFiveQuestion}
                    reduceScore={this.reduceScore}
                    showCover={this.showCover}
                />
                <FiveQuestion
                    addScore={this.addScore}
                    nextQuestion={this.nextQuestion}
                    backPrevious={this.backPrevious}
                    show={this.state.FiveQuestionShow}
                    showSixQuestion={this.showSixQuestion}
                    reduceScore={this.reduceScore}
                    showCover={this.showCover}
                />
                <SixQuestion
                    addScore={this.addScore}
                    backPrevious={this.backPrevious}
                    show={this.state.SixQuestionShow}
                    reduceScore={this.reduceScore}
                    sendALLScore={this.sendALLScore}
                    showCover={this.showCover}
                />
            </div>
            </Fragment>
        )
    }
}
