import React, { Component } from 'react'
import 'animate.css'
import './index.scss'
import QueueAnim from 'rc-queue-anim';
import PubSub from 'pubsub-js';
import Slash from '../../../assets/image/question/Slash.png'
import reverseFrame24 from '../../../assets/image/question/reverse24.png'
import Frame24 from '../../../assets/image/question/24.png'
import Frame23 from '../../../assets/image/question/23.png'
import blacktree from '../../../assets/image/question/blacktree.png'
const base = document.documentElement.clientHeight
export default class ThreeQuestion extends Component {
    state = {
        three_question_title: "three_question_title",
        three_question_one: "three_question_one",
        three_question_two: "three_question_two",
        tree: '',
        coverPage_three: "coverPage_three",
        choice: '',
        isClicked: false

    }
    three_question_title = React.createRef()
    three_previous_question = React.createRef()
    three_question_options = React.createRef()
    three_question = React.createRef()
    coverPage_three = React.createRef()
    optionOne = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(2, 2, 0, 1, 0)
            this.coverPage_three.current.style.display = 'none'
            this.setState({
                three_question_two: "three_question_two animate__animated animate__fadeOutDown",
                choice: 'A',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    three_question_one: "three_question_one animate__animated  animate__fadeOutDown",
                    tree: "treeDisappear",
                    three_question_title: "three_question_title  animate__animated  animate__fadeOutDown",
                    //    coverPage_three:"coverPage_three  animate__animated  animate__fadeOutDown",
                }, () => {
                    this.three_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000)
            setTimeout(() => {
                this.props.nextQuestion((480 / 2915) * 100)
                this.props.showFourQuestion()
                PubSub.publish('changeShowFourButton', { choice: 'A' })
            }, 2000);
        }
    }
    optionTwo = () => {
        if (this.state.isClicked !== true) {
            this.props.addScore(0, 0, 2, 1, 2)
            this.coverPage_three.current.style.display = 'none'
            this.setState({
                three_question_one: "three_question_one animate__animated animate__fadeOutDown",
                choice: "B",
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    three_question_two: "three_question_two animate__animated  animate__fadeOutDown",
                    tree: "treeDisappear",
                    three_question_title: "three_question_title  animate__animated  animate__fadeOutDown",
                    //    coverPage_three:"coverPage_three  animate__animated  animate__fadeOutDown",
                }, () => {
                    this.three_previous_question.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000)
            setTimeout(() => {
                this.props.nextQuestion((480 / 2915) * 100)
                this.props.showFourQuestion()
                PubSub.publish('changeShowFourButton', { choice: "B" })

            }, 2000);
        }

    }
    backPrevious = () => {
        const { choice } = this.state
        if (choice === 'A') {
            this.props.reduceScore(4, 1, 0, 0, 0)
        } else if (choice === 'B') {
            this.props.reduceScore(0, 0, 2, 0, 3)
        } else if (choice === 'C') {
            this.props.reduceScore(0, 0, 1, 3, 1)
        } else if (choice === 'D') {
            this.props.reduceScore(0, 3, 1, 1, 0)
        } else {
            this.props.reduceScore(0, 0, 0, 0, 0)
        }
        PubSub.publish('changeStateTwo', {})
        this.props.backPrevious((540 / 2915) * 100)
        this.setState({
            three_question_title: "three_question_title  animate__animated animate__fadeOutDown",
            three_question_one: "three_question_one  animate__animated animate__fadeOutDown",
            three_question_two: "three_question_two  animate__animated animate__fadeOutDown",
            // coverPage_three:"coverPage_three  animate__animated animate__fadeOutDown",
            tree: ''
        })
        this.three_previous_question.current.style.display = 'none'
        this.coverPage_three.current.style.display = 'none'
        // this.three_question.current.style.display='none'
        // this.three_question_options.current.style.display='none'
    }
    componentDidMount() {
        if (base > 750) {
            this.three_question_title.current.style.fontSize = '28px'
        }
        this.token = PubSub.subscribe('changeShowButton', (_, data) => {
            //    console.log(data);
            // this.coverPage_three.current.style.display='block'
            this.props.showCover(true)
            this.three_previous_question.current.style.display = 'block'
            this.setState({
                three_question_title: "three_question_title animate__animated  animate__fadeInDown",
                three_question_one: "three_question_one animate__animated  animate__fadeInDown",
                three_question_two: "three_question_two animate__animated  animate__fadeInDown",
                // coverPage_three:"coverPage_three animate__animated  animate__fadeInDown",
                tree: '',
                choice: data.choice
            }, () => {
                this.three_previous_question.current.style.display = 'block'
                setTimeout(() => {
                    this.coverPage_three.current.style.display = 'block'
                }, 1500)
            })
        })
        this.token1 = PubSub.subscribe('changeStateThree', (_, data) => {
            this.coverPage_three.current.style.display = 'none'
            this.setState({
                three_question_title: "three_question_title animate__animated  animate__fadeInDown",
                three_question_one: "three_question_one animate__animated  animate__fadeInDown",
                three_question_two: "three_question_two animate__animated  animate__fadeInDown",
                // coverPage_three:"coverPage_three animate__animated  animate__fadeInDown",
                tree: '',
                isClicked: false
            }, () => {
                this.three_previous_question.current.style.display = 'block'
            })
            setTimeout(() => {
                this.coverPage_three.current.style.display = 'block'
                this.setState({
                    coverPage_three: "coverPage_three coverShow",
                })
            }, 2000)
        })
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
        PubSub.unsubscribe(this.token1)
    }
    render() {
        return (
            <div className="three_question"
                style={{
                    display: this.props.show ? 'block' : 'none',
                    // top:(2915-base/1.4-base-base/1.2)+'px'
                }} ref={this.three_question}>
                <div className={this.state.coverPage_three} ref={this.coverPage_three} style={{ display: "none" }}></div>
                <div className={this.state.three_question_title} ref={this.three_question_title}>
                    <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginRight: '-3px', marginTop: '-5px' }}></img>
                    <img src={Slash} alt="err" style={{ width: '18px', height: '23px', marginLeft: '-4px', marginTop: '-5px' }}></img>
                    移走前面倒下的树，才可以继续前进，你会？
                </div>
                <div className="tree">
                    {/* <img src={whitetree} className={this.state.tree} style={{ zIndex: -8, top: '163.5px' }} alt="err" ></img> */}
                    <img src={blacktree} className={this.state.tree} style={{ zIndex: 7 }} alt="err" ></img>
                    {/* <img src={cover}  alt="err"></img> */}
                </div>
                <div className="three_question_options" ref={this.three_question_options}>
                    <QueueAnim
                        key="1"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="three_question_one"
                            className={this.state.three_question_one}
                            onClick={this.optionOne}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                                marginLeft: (55 / 375) * 100 + 'vw'
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '70vw', height: "40px", position: 'absolute', left: '5px', top: '4vw' }}></img>
                            <img src={reverseFrame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px', right: '-9vw' }}></img>
                            <span>A.呼吁路人来合力移走它</span>
                        </div>
                    </QueueAnim>
                    <QueueAnim
                        key="2"
                        delay="700"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 800] }
                        ]}>
                        <div key="three_question_one"
                            className={this.state.three_question_two}
                            onClick={this.optionTwo}
                            style={{
                                lineHeight: (70 / base) * 100 + 'vh',
                            }}>
                            <img src={Frame23} alt="err" style={{ width: '70vw', height: "40px", position: 'absolute', left: '10px', top: '15px' }}></img>
                            <img src={Frame24} alt="err" style={{ width: '42px', height: "40px", position: 'absolute', top: '5px' }}></img>
                            <span>B.自己去找工具移走它</span>
                        </div>
                    </QueueAnim>
                </div>
                <div className="three_previous_question" onClick={this.backPrevious} style={{ display: 'block' }} ref={this.three_previous_question}>
                    上一题
                </div>
            </div>
        )
    }
}
