import React, { Component } from 'react'
import './testResult.scss'
// import Chart from './chart'
export default class TestResult extends Component {
    render() {
        return (
            <div>
                {/* <Chart/> */}
                <div className="test"></div>
                TestResult
            </div>
        )
    }
}
