import React, { Component,Fragment} from 'react'
import SmallBall from '../../../assets/image/question/crystalBall.png'
import BigBall from '../../../assets/image/question/BigCrystalBall.png'
import Frame24 from '../../../assets/image/question/24.png'
import Slash from '../../../assets/image/question/Slash.png'
import Wizard from '../../../assets/image/question/Wizard.png'
import Frame23 from '../../../assets/image/question/23.png'
import square1 from '../../../assets/image/question/Wizard-square1.png'
import square2 from '../../../assets/image/question/Wizard-square2.png'
import square3 from '../../../assets/image/question/Wizard-square3.png'
import square4 from '../../../assets/image/question/Wizard-square4.png'
import square5 from '../../../assets/image/question/Wizard-square5.png'
import 'animate.css'
import './index.scss'
import QueueAnim from 'rc-queue-anim';
import PubSub from 'pubsub-js'
const base = document.documentElement.clientHeight

export default class TwoQuestion extends Component {
    state = {
        evaluation: "two_question_title_evaluation",
        subject: "two_question_title_subject",
        two_question_Wizard: "two_question_Wizard",
        two_question_Wizard_copy: "two_question_Wizard_copy",
        two_question_Wizard_square: "two_question_Wizard_square",
        two_question_Wizard_square_copy: "two_question_Wizard_square_copy",
        two_question_one: "two_question_one",
        two_question_two: "two_question_two",
        two_question_three: "two_question_three",
        two_question_four: "two_question_four",
        square: "square",
        // coverPage_two:"coverPage_two",
        choice: '',
        isClicked: false,
        base: base
    }
    subjectRef = React.createRef()
    bigBall = React.createRef()
    smallBall = React.createRef()
    two_question_options = React.createRef()
    previous_question = React.createRef()
    evaluation = React.createRef()
    coverPage_two = React.createRef()
    smallBall_copy = React.createRef()
    coverPage_two_top=React.createRef()
    optionOne = () => {
        if (this.state.isClicked !== true) {
            PubSub.publish('showCover',{})
            this.props.callback(4, 1, 0, 0, 0)
            this.setState({
                two_question_two: "two_question_two animate__animated animate__fadeOutDown",
                two_question_three: "two_question_three animate__animated animate__fadeOutDown",
                two_question_four: "two_question_four animate__animated animate__fadeOutDown",
                choice: 'A',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    two_question_one: "two_question_one animate__animated  animate__fadeOutDown",
                    subject: "two_question_title_subject  animate__animated  animate__fadeOutDown",
                    // coverPage_two:"coverPage_two coverDisappear"
                }, () => {
                    this.bigBall.current.style.display = 'none'
                    // this.coverPage_two_top.current.style.display='none'
                    this.coverPage_two.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000)
            setTimeout(() => {
                this.setState({
                    two_question_Wizard: "two_question_Wizard   wizardDisappear",
                    two_question_Wizard_copy: "two_question_Wizard_copy  wizardDisappear",
                    square: "square squareDisappear"
                }, () => {
                    this.previous_question.current.style.display = 'none'
                })
            }, 1500);
            setTimeout(() => {
                this.props.nextQuestion((540 / 2915) * 100)
            }, 2800)
            setTimeout(() => {
                this.props.showThreeQuestion()
                PubSub.publish('changeShowButton', { choice: 'A' })
            }, 4000);
        }
    }
    optionTwo = () => {
        if (this.state.isClicked !== true) {
             PubSub.publish('showCover',{})
            this.props.callback(0, 0, 2, 0, 3)
            // this.coverPage_two.current.style.display = 'none'
            this.setState({
                two_question_one: "two_question_one animate__animated  animate__fadeOutDown",
                two_question_three: "two_question_three animate__animated animate__fadeOutDown",
                two_question_four: "two_question_four animate__animated animate__fadeOutDown",
                choice: 'B',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    two_question_two: "two_question_two animate__animated animate__fadeOutDown",
                    subject: "two_question_title_subject  animate__animated  animate__fadeOutDown",
                    // coverPage_two:"coverPage_two animate__animated  animate__fadeOutDown"
                    coverPage_two: "coverPage_two coverDisappear"
                }, () => {
                    this.bigBall.current.style.display = 'none'
                    // this.coverPage_two_top.current.style.display='none'
                    this.coverPage_two.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000)
            setTimeout(() => {
                this.setState({
                    two_question_Wizard: "two_question_Wizard   wizardDisappear",
                    two_question_Wizard_copy: "two_question_Wizard_copy  wizardDisappear",
                    square: "square squareDisappear"
                }, () => {
                    this.previous_question.current.style.display = 'none'
                })
            }, 1500);
            setTimeout(() => {
                this.props.nextQuestion((540 / 2915) * 100)
            }, 2800)
            setTimeout(() => {
                this.props.showThreeQuestion()
                PubSub.publish('changeShowButton', { choice: 'B' })
            }, 4000);
        }

    }
    optionThree = () => {
        if (this.state.isClicked !== true) {
            PubSub.publish('showCover',{})
            this.props.callback(0, 0, 1, 3, 1)
            // this.coverPage_two.current.style.display = 'none'
            this.setState({
                two_question_one: "two_question_one animate__animated  animate__fadeOutDown",
                two_question_two: "two_question_two animate__animated animate__fadeOutDown",
                two_question_four: "two_question_four animate__animated animate__fadeOutDown",
                choice: 'C',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    two_question_three: "two_question_three animate__animated animate__fadeOutDown",
                    subject: "two_question_title_subject  animate__animated  animate__fadeOutDown",
                    // coverPage_two:"coverPage_two animate__animated  animate__fadeOutDown"
                    coverPage_two: "coverPage_two coverDisappear"
                }, () => {
                    this.bigBall.current.style.display = 'none'
                    // this.coverPage_two_top.current.style.display='none'
                    this.coverPage_two.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000)
            setTimeout(() => {
                this.setState({
                    two_question_Wizard: "two_question_Wizard   wizardDisappear",
                    two_question_Wizard_copy: "two_question_Wizard_copy  wizardDisappear",
                    square: "square squareDisappear"
                }, () => {
                    this.previous_question.current.style.display = 'none'
                })
            }, 1500);
            setTimeout(() => {
                this.props.nextQuestion((540 / 2915) * 100)
            }, 2800)
            setTimeout(() => {
                this.props.showThreeQuestion()
                PubSub.publish('changeShowButton', { choice: 'C' })
            }, 4000);

        }
    }
    optionFour = () => {
        if (this.state.isClicked !== true) {
            PubSub.publish('showCover',{})
            this.props.callback(0, 3, 1, 1, 0)
            // this.coverPage_two.current.style.display = 'none'
            this.setState({
                two_question_one: "two_question_one animate__animated  animate__fadeOutDown",
                two_question_two: "two_question_two animate__animated animate__fadeOutDown",
                two_question_three: "two_question_three animate__animated animate__fadeOutDown",
                choice: 'D',
                isClicked: true
            })
            setTimeout(() => {
                this.setState({
                    two_question_four: "two_question_four animate__animated animate__fadeOutDown",
                    subject: "two_question_title_subject  animate__animated  animate__fadeOutDown",
                    // coverPage_two:"coverPage_two animate__animated  animate__fadeOutDown"
                    coverPage_two: "coverPage_two coverDisappear"
                }, () => {
                    this.bigBall.current.style.display = 'none'
                    // this.coverPage_two_top.current.style.display='none'
                    this.coverPage_two.current.style.display = 'none'
                })
                this.props.showCover(false)
            }, 1000)
            setTimeout(() => {
                this.setState({
                    two_question_Wizard: "two_question_Wizard   wizardDisappear",
                    two_question_Wizard_copy: "two_question_Wizard_copy  wizardDisappear",
                    square: "square squareDisappear"
                }, () => {
                    this.previous_question.current.style.display = 'none'
                    // this.coverPage_two_top.current.style.display='none'
                })
            }, 1500);
            setTimeout(() => {
                this.props.nextQuestion((540 / 2915) * 100)
            }, 2800)
            setTimeout(() => {
                this.props.showThreeQuestion()
                PubSub.publish('changeShowButton', { choice: 'D' })
            }, 4000);
        }
    }

    backPrevious = () => {
        this.props.backPrevious((480 / 2915) * 100)
        // this.previous_question.current.style.display='none'
        // this.props.changeShow()
        this.setState({
            // evaluation:"two_question_title_evaluation",
            // subject:"two_question_title_subject",
            two_question_Wizard: "two_question_Wizard ",
            two_question_Wizard_square: "two_question_Wizard_square ",
            two_question_one: "two_question_one ",
            two_question_two: "two_question_two",
            two_question_three: "two_question_three ",
            two_question_four: "two_question_four ",
            square: "square ",
        }, () => {
            this.previous_question.current.style.display = 'block'
            if (base > 750) {
                this.smallBall_copy.current.style.display = 'none'
            } else {
                this.smallBall.current.style.display = 'none'
            }

            this.bigBall.current.style.display = 'none'
            this.two_question_options.current.style.display = 'none'
            this.evaluation.current.style.display = 'hidden'
            this.props.changeShow()
        })

    }

    componentDidMount() {
        try {
            if (base > 750) {
                this.subjectRef.current.style.fontSize = '28px'
            }
            this.token = PubSub.subscribe('changeStateTwo', (_, data) => {
                // this.coverPage_two.current.style.display='block'
                this.setState({
                    // evaluation:"two_question_title_evaluation",
                    subject: "two_question_title_subject animate__animated  animate__fadeInDown",
                    two_question_Wizard: "two_question_Wizard ",
                    two_question_Wizard_copy: "two_question_Wizard_copy",
                    two_question_Wizard_square: "two_question_Wizard_square ",
                    two_question_Wizard_square_copy: "two_question_Wizard_square_copy",
                    two_question_one: "two_question_one animate__animated  animate__fadeInDown",
                    two_question_two: "two_question_two animate__animated  animate__fadeInDown",
                    two_question_three: "two_question_three animate__animated  animate__fadeInDown",
                    two_question_four: "two_question_four animate__animated  animate__fadeInDown",
                    coverPage_two: "coverPage_two coverShow",
                    square: "square ",
                    isClicked: false
                }, () => {
                    this.previous_question.current.style.display = 'block'
                    setTimeout(() => {
                        this.coverPage_two.current.style.display = 'block'
                        // this.setState({
                        //     coverPage_two:"coverPage_two coverShow",
                        // })
                    }, 2000)
                })
                // this.coverPage_two.current.style.display='block'
                this.bigBall.current.style.display = 'block'
                this.subjectRef.current.style.display = 'block flex'
            })
            this.token1 = PubSub.subscribe('changeShowTwo', (_, data) => {
                this.subjectRef.current.style.display = 'none'
                // this.coverPage_two.current.style.display='none'
                // this.smallBall.current.style.display = 'block'
                if (base > 750) {
                    this.smallBall_copy.current.style.display = 'block'
                }
                this.props.showCover(false)
                  // this.smallBall.current.style.display='none'
                //   this.props.changeCover(false)
                setTimeout(() => {
                    this.two_question_options.current.style.display = 'block'
                    this.bigBall.current.style.display = 'block'
                    if (base > 750) {
                        this.smallBall_copy.current.style.display = 'none'
                    }
                    else {
                        this.smallBall.current.style.display = 'none'
                    }
                    // this.coverPage_two.current.style.display='block'
                    this.setState({
                        evaluation: "two_question_title_evaluation animate__animated animate__fadeOutDown",
                        subject: "two_question_title_subject animate__animated  animate__fadeInDown",
                        two_question_Wizard: "two_question_Wizard ",
                        two_question_Wizard_square: "two_question_Wizard_square",
                        two_question_one: "two_question_one animate__animated  animate__fadeInDown",
                        two_question_two: "two_question_two animate__animated  animate__fadeInDown",
                        two_question_three: "two_question_three animate__animated  animate__fadeInDown ",
                        two_question_four: "two_question_four animate__animated  animate__fadeInDown",
                        square: "square animate__animated  animate__fadeInDown",
                        // coverPage_two:"coverPage_two animate__animated  animate__fadeInDown"  
                        // coverPage_two:"coverPage_two coverShow"
                    }, () => {
                        this.coverPage_two.current.style.display = 'block'
                        this.subjectRef.current.style.display = 'block'
                        // // this.smallBall.current.style.display='none'
                        // this.props.changeCover(false)

                        this.previous_question.current.style.display = 'block'
                        console.log('asdaiojsdpjasdas');
                    })
                }, 2500);
            })
            
        } catch (error) {
            console.log(error);
        }
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
        PubSub.unsubscribe(this.token1)
            this.setState = ()=>false;
    }
    render() {
        return (
            <Fragment>
              <div className="coverPage_two_top" ref={this.coverPage_two_top}></div>
            <div className="two_question"
                style={{
                    display: this.props.show ? 'block' : 'none',
                    //  top:(2915-base/1.5-base)+'px'
                }}
            >
                {/* <div className="coverPage_two_top" ref={this.coverPage_two_top}></div> */}
                {
                    this.state.base < 750 ? [
                        <div key="A">
                            {/* <div className="coverPage_two_top" ref={this.coverPage_two_top}></div> */}
                            <div className="coverPage_two" ref={this.coverPage_two} style={{ display: 'none' }}></div>

                            <div className={this.state.two_question_Wizard}>
                                <img src={Wizard} className="Wizard" alt="err"></img>
                                <img src={SmallBall} className="smallBall" style={{ color: '#FFFFFF', width: '64px', height: '96px' }} alt="err" ref={this.smallBall}></img>
                            </div>
                            <div className={this.state.two_question_Wizard_square}>
                                <img src={square1} id="square1" className={this.state.square} alt="err"></img>
                                <img src={square2} id="square2" className={this.state.square} alt="err"></img>
                                <img src={square3} id="square3" className={this.state.square} alt="err"></img>
                                <img src={square4} id="square4" className={this.state.square} alt="err"></img>
                                <img src={square5} id="square5" className={this.state.square} alt="err"></img>
                            </div>
                            <div className="two_question_title" >
                                <div className={this.state.evaluation} ref={this.evaluation}>Hi，我的水晶球可以看见好友对你的评价</div>
                                <div className={this.state.subject} ref={this.subjectRef} style={{ display: 'flex' }}>
                                    <img src={Slash} alt="404" style={{ width: '18px', height: '23px', marginRight: '-3px', marginTop: '-5px' }}></img>
                                    <img src={Slash} alt="404" style={{ width: '18px', height: '23px', marginLeft: '-4px', marginTop: '-5px' }}></img>
                                    <span className="subject">遇见你的好友你希望TA给你什么样的评价?</span>
                                </div>
                            </div>
                            <div className="two_question_ball">
                                {/* <div className="smallBall">
                                    <img src={SmallBall} style={{ color: '#FFFFFF', width: '64px', height: '96px' }} alt="err" ref={this.smallBall}></img>
                                </div> */}
                                <img src={BigBall} ref={this.bigBall} alt="err"></img>
                            </div>
                            <div className="two_question_options" style={{ display: 'none' }} ref={this.two_question_options}>
                                <QueueAnim
                                    key="1"
                                    delay="700"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [0, 800] }
                                    ]}>
                                    <div key="two_question_one"
                                        className={this.state.two_question_one}
                                        onClick={this.optionOne}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>A.善于观察</span>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    key="2"
                                    delay="1000"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [20, 800] }
                                    ]}>
                                    <div key="two_question_two"
                                        className={this.state.two_question_two}
                                        onClick={this.optionTwo}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>B.动手能力强，具有想象力</span>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    key="3"
                                    onEnd={this.onEnd}
                                    delay="1300"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [20, 800] }
                                    ]}>
                                    <div key="two_question_three"
                                        className={this.state.two_question_three}
                                        onClick={this.optionThree}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>C.责任心强,思维缜密</span>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    key="4"
                                    onEnd={this.onEnd}
                                    delay="1500"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [20, 800] }
                                    ]}>
                                    <div key="two_question_three"
                                        className={this.state.two_question_four}
                                        onClick={this.optionFour}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>D.审美好,富有有创造力</span>
                                    </div>
                                </QueueAnim>
                            </div>
                            <div className="two_previous_question" onClick={this.backPrevious} ref={this.previous_question}>
                                上一题
                            </div>
                        </div>
                    ] : [
                        <div key="B">
                            <div className="coverPage_two" ref={this.coverPage_two} style={{ display: 'none' }}></div>
                            {/* <div className="coverPage_two_top_copy" ref={this.coverPage_two_top_copy}></div> */}
                            {/* <div className={this.state.coverPage_two} style={{display:'none'}} ref={this.coverPage_two}></div> */}
                            <div className={this.state.two_question_Wizard_copy}>
                                <img src={Wizard} className="Wizard_copy" alt="err"></img>
                                <img src={SmallBall} className="smallBall_copy" style={{ color: '#FFFFFF', width: '64px', height: '96px' }} alt="err" ref={this.smallBall_copy}></img>
                            </div>
                            <div className={this.state.two_question_Wizard_square_copy}>
                                <img src={square1} id="square1" className={this.state.square} alt="err"></img>
                                <img src={square2} id="square2" className={this.state.square} alt="err"></img>
                                <img src={square3} id="square3" className={this.state.square} alt="err"></img>
                                <img src={square4} id="square4" className={this.state.square} alt="err"></img>
                                <img src={square5} id="square5" className={this.state.square} alt="err"></img>
                            </div>
                            {/* <div className="coverPage_two"></div> */}
                            <div className="two_question_title_copy" >
                                <div className={this.state.evaluation} ref={this.evaluation}>Hi，我的水晶球可以看见好友对你的评价</div>
                                <div className={this.state.subject} ref={this.subjectRef}>
                                    <img src={Slash} alt="404" style={{ width: '18px', height: '23px', marginRight: '-3px', marginTop: '-5px' }}></img>
                                    <img src={Slash} alt="404" style={{ width: '18px', height: '23px', marginLeft: '-4px', marginTop: '-5px' }}></img>
                                    遇见你的好友你希望TA给你什么样的评价?
                                </div>
                            </div>
                            <div className="two_question_ball_copy">
                                {/* <div className="smallBall">
                                    <img src={SmallBall} style={{ color: '#FFFFFF', width: '64px', height: '96px' }} alt="err" ref={this.smallBall}></img>
                                </div> */}
                                <img src={BigBall} ref={this.bigBall} alt="err"></img>
                            </div>
                            <div className="two_question_options_copy" style={{ display: 'none' }} ref={this.two_question_options}>
                                <QueueAnim
                                    key="6"
                                    delay="700"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [0, 800] }
                                    ]}>
                                    <div key="two_question_one"
                                        className={this.state.two_question_one}
                                        onClick={this.optionOne}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>A.善于观察</span>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    key="7"
                                    delay="1000"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [20, 800] }
                                    ]}>
                                    <div key="two_question_two"
                                        className={this.state.two_question_two}
                                        onClick={this.optionTwo}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>B.动手能力强，具有想象力</span>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    key="8"
                                    onEnd={this.onEnd}
                                    delay="1300"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [20, 800] }
                                    ]}>
                                    <div key="two_question_three"
                                        className={this.state.two_question_three}
                                        onClick={this.optionThree}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>C.责任心强,思维缜密</span>
                                    </div>
                                </QueueAnim>
                                <QueueAnim
                                    key="9"
                                    onEnd={this.onEnd}
                                    delay="1500"
                                    animConfig={[
                                        { opacity: [1, 0], translateY: [20, 800] }
                                    ]}>
                                    <div key="two_question_three"
                                        className={this.state.two_question_four}
                                        onClick={this.optionFour}
                                        style={{
                                            lineHeight: (80 / base) * 100 + 'vh',
                                        }}>
                                        <img src={Frame23} alt="23" style={{ width: '86vw', height: "50px", position: 'absolute', left: '10px', top: '15px' }}></img>
                                        <img src={Frame24} alt="23" style={{ width: '42px', height: "50px", position: 'absolute', top: '5px' }}></img>
                                        <span>D.审美好,富有创造力</span>
                                    </div>
                                </QueueAnim>
                            </div>
                            <div className="two_previous_question_copy" onClick={this.backPrevious} ref={this.previous_question}>
                                上一题
                            </div>
                        </div>
                    ]
                }
            </div>
            </Fragment>
        )
        
    }
}
