import React, { Fragment, PureComponent } from 'react'
import { Progress } from 'antd';
import letter from '../../assets/image/homePage/letter.png'
import halo from '../../assets/image/homePage/halo.png'
import circle from '../../assets/image/homePage/circle2.png'
import dynamic_grass from '../../assets/image/homePage/dynamic_grass.gif'
//import transport_success from '../../assets/image/homePage/transport_success.png'
import 'antd/dist/antd.css'
import "animate.css"
import './index.scss'
export default class Loading extends PureComponent {
    state={
        loadingStyle1:{width:'100%'},
        percent:0,
        flag:true,
        className:'loadingOuter',
        letterStyle:220,
        height:0,
        margin_top:0,
        margin_top2:0,
        margin_top3:0
    }
    componentDidMount(){
        const {percent} = this.state
        let count = percent;
        // let marginTop = margin_top
        // let marginTop2 = margin_top2
        // let marginTop3 = margin_top3
        let timer = setInterval(()=>{
            count++
            this.setState({percent:count})
            if(count>=100){
                clearInterval(timer)
                /**
                 * 这里的定时器需要根据loading页面加载时间进行调整
                 */
                this.setState({flag:false})
                setTimeout(()=>{
                    this.setState({className:'loadingOuter animate__animated animate__fadeOut animate__fast'})
                },1500)
                setTimeout(()=>{              
                    this.setState({loadingStyle1:{display:'none'}})//5
                },2000)
            }
        },30)
    }

    render() {
        const {percent,loadingStyle1,flag,className,margin_top,margin_top2,margin_top3} = this.state
        return (
            <Fragment>
                <div className={className} style={loadingStyle1}>
                <div className="loading_empty"></div>
                    <div className="loading_container">
                        <div className="topMask"></div>
                        <div className="bottomMask"></div>
                        <img src={dynamic_grass} alt="err" id="dynamic_grass"/>
                        <div className="lineDrop">
                            <div className="line" style={{marginTop:`${margin_top}px`,opacity:'0.8',left:'30px'}} id="line1"></div>
                            {/* marginLeft60 50 */}
                            <div className="line" style={{marginTop:`${margin_top2}px`,marginLeft:'60px',opacity:'0.93'}} id="line2"></div>
                            <div className="line" style={{marginTop:`${margin_top3}px`,marginLeft:'51px',opacity:'0.87'}} id="line3"></div>

                            {/*   */}
                            {/* <div className="line" style={{height:`${height}px`,marginTop:`${margin_top}px`}}></div> */}
                        </div>
                            {/* 信封 */}
                            <div className="loadingLetter">
                                <img src={letter} alt="err" id="loading_letter_img" className="animate__animated shakeY1"/>
                                <img src={halo} alt="err"/>
                                <img src={circle} alt="err"/>
                            </div>
                            {/* 进度条 */}
                            <div className="progressBar">
                                <Progress
                                    percent={percent} 
                                    status="active" 
                                    strokeColor="#83FFF8"
                                    trailColor="#4C909E"
                                    strokeWidth="2px"
                                    showInfo={false}
                                />
                                <span className="finish">{flag?<span>传输中 {percent}%</span>:<span className="animate__animated animate__fadeIn animate__faster" id="transport_finise">传输完成</span>}</span>
                            </div>
                        
                    </div>
                </div>


            </Fragment>
        )
    }
}
