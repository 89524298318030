import React, { PureComponent } from 'react'
import './index.scss'
import qrCode from '../../assets/image/Join_us/qrCode.png'
import back from '../../assets/image/Join_us/back.png'
export default class index extends PureComponent {
    back=()=>{
        this.props.history.replace('/Result')
    }
    render() {
        return (
            <div>
                <div id="join_us_background"></div>
                <div id="join_us_solidColor"></div>
                    <div className="outer">
                        <div className="join_us_top" onClick={this.back}>
                            <img src={back} alt="err" id="back_png"/>
                        </div>
                        <div className="join_us_entry">
                            <span id="join_us_entry">报名入口:</span>
                            <div className="qrCode"><img src={qrCode} alt="err" id="qrCode"/><div id="under_qrCode">重邮帮--青春邮约小程序</div></div>
                        </div>
                        <div className="learn_more">
                            <span id="learn_more">了解更多:</span><br/>
                            <span id="group1">红岩网校工作站招新一群:870557948</span><br/>
                            <span id="group2">红岩网校工作站招新二群:763915592</span>
                        </div>
                            {/* <img src={mashroom} alt="err" id="join_us_mashroom"/> */}
                            {/* <img src={static_firefly} alt="err" id="static_firefly"/> */}
                            {/* <img src={halo} alt="err" id="join_us_halo"/> */}
                        
                    </div>
            </div>
        )
    }
}
