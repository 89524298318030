import React, { Fragment, PureComponent } from 'react'
import PubSub from 'pubsub-js'
import bigfirefly from '../../assets/image/homePage/firefly_good.gif'
import bigfirefly1 from '../../assets/image/homePage/firefly_good1.gif'
import right_opacity_firefly from '../../assets/image/homePage/right_opacity_firefly.gif'
import opacityFirefly from '../../assets/image/homePage/opacityFirefly.gif'
// import glitch_font from '../../assets/image/homePage/glitch_font.gif'
import './index.scss'
import grass from '../../assets/image/homePage/grass_nofirefly.png'
import './font.scss'
export default class index extends PureComponent {
    state={
        flag:false,
        reserveStyle:{},
        mark:true,
        outershellClass:"animate__animated fadeInUp1 animate__slow",
        id:'outerShell',
        number:8,
        newStyle:{animationDelay:'5.5s'},
        offTV_style:{display:'none'},
        ellipseStyle:{display:'none'}
    }
    componentDidMount(){
        const that = this
        const mySubscribe = function(msg,data){
            //console.log(data) //true
            that.setState({flag:data})
        }
        this.token = PubSub.subscribe('flag',mySubscribe)

    }
    componentWillUnmount(){
        PubSub.unsubscribe(this.token)
    }
    componentDidUpdate(){
        const {flag,mark,} = this.state
        if(flag===true && mark === true){
            //关电视效果
            this.setState({offTV_style:{display:'block'}})
            //同步遮罩速度
            setTimeout(()=>{
                this.setState({ellipseStyle:{display:'block'}})
            },550)
            setTimeout(()=>{      
                this.setState({newStyle:{display:'none'}})
            },825)
            setTimeout(()=>{
                this.setState({offTV_style:{display:'none'}})
            },850)
            this.setState({mark:false},()=>{
                this.props.changeFlag(this.state.mark)
            })
            setTimeout(()=>{
                this.setState({number:0.5}) 
                this.setState({outershellClass:"animate__animated animate__fadeOut animate__fast"})        
                this.setState({newStyle:{animationDelay:'0.5s'}})
            },10)

        }

    }
    
    render() {
        const {outershellClass,id,newStyle,ellipseStyle,offTV_style} = this.state
        return (
            <Fragment> 
                <div className="offTV_mask" style={offTV_style}>
                    <div className="content_top"></div>
                    <div className="ellipse" style={ellipseStyle}></div>
                    <div className="content_bottom"></div>
                </div>
                <div className={outershellClass} id={id} style={newStyle}>
                        <div id="redrock_logo" >
                            红岩网校工作站
                            <div className="emptyWhite"></div>
                        </div>
                    <div className="Letter_innercontent animate__animated">
                        <div className="letter_empty"></div>
                        <div className="letterShell"></div>
                        <div id="Letter_box" className="animate__animated animate__bounce">
                            <div className="Letter_box_text">你有一封新邮件待查收</div>
                            <button className="Letter_box_btn"><span>立即查看</span></button>
                            {/* 右边的萤火虫 */}
                            <img src={right_opacity_firefly} alt="err" className="firefly" id="firefly1"/>
                            {/* 左边两个萤火虫 */}
                            <img src={opacityFirefly} alt="err" className="firefly" id="firefly2"/>
                            {/* <img src={new_firefly} alt="err" className="firefly" id="firefly3"/> */}
                            <img src={bigfirefly1} alt="err" className="flrefly" id="firefly3"/>
                            <img src={bigfirefly} alt="err" className="flrefly" id="firefly4"/>
                            <img src={bigfirefly1} alt="err" className="flrefly" id="firefly5"/>
                            <img className="Letter_box_grass" src={grass} alt="err" onClick={this.changeScene} style={{display:'block'}}/>
                        </div>
                        <div id="showLetter" className="animate__animated animate__zoomIn"></div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
