import React, { PureComponent,Fragment } from 'react'
import fabric from '../../assets/image/result/fabric.png'
import NewRadar from './newRadar'
import butterfly from '../../assets/image/result/butterfly.png'
import small_icon from '../../assets/image/result/small_icon.png'
import qrCode from '../../assets/image/result/qrCode.png'
import splitLine from '../../assets/image/result/splitLine.png'
import triangle from '../../assets/image/result/triangle.png'
import PM from '../../assets/image/result/PM.png'
import DESIGN from '../../assets/image/result/DESIGN.png'
import RD from '../../assets/image/result/MD.png'
import WEB from '../../assets/image/result/WEB.png'
import SRE from '../../assets/image/result/SRE.png'
import default_halo from '../../assets/image/result/default_halo.png'
import bottom_grass from '../../assets/image/result/bottom_grass.png'
import bottom_halo from '../../assets/image/result/bottom_halo.png'
import new_mashroom from '../../assets/image/result/new_mashroom.png'
import close from '../../assets/image/result/close.png'
import res_static_firefly from '../../assets/image/result/res_static_firefly.png'
import rdTitle from '../../assets/image/result/rdTitle.png'
import pmTitle from '../../assets/image/result/pmTitle.png'
import desTitle from '../../assets/image/result/desTitle.png'
import sreTitle from '../../assets/image/result/sreTitle.png'
import webTitle from '../../assets/image/result/webTitle.png'
import gradient from '../../assets/image/result/gradient.png'
// import bigGradient from '../../assets/image/result/bigGradient.png'
import html2canvas from 'html2canvas'
import './index.scss'
export default class Result extends PureComponent {
    state={
        btnStyle:{},
        save_pic:{display:'none'},
        btnClass:'',
        mashroom_bottom:{top:'-100px'},
        splitLineStyle : {},
        rotate:{transform:'rotate(90deg)'},
        rotate2:{transform:'rotate(90deg)'},


        PMstyle:{display:'none'},
        DESIGNstyle:{display:'none'},
        WEBstyle:{display:'none'},
        RDstyle:{display:'none'},
        SREstyle:{display:'none'},


        flag:true,
        flag2:true,
        flag3:true,
        flag4:true,
        flag5:true,

        
        rotateStyle:{},        
        rotateStyle2:{},  
        rotateStyle3:{},  
        rotateStyle4:{},  
        rotateStyle5:{},  


        rotateClass:'triangle1',
        rotateClass2:'triangle1',
        rotateClass3:'triangle1',
        rotateClass4:'triangle1',
        rotateClass5:'triangle1',


        fadeClass:'animate__animated animate__fadeInDown',
        fadeClass2:'animate__animated animate__fadeInDown',
        fadeClass3:'animate__animated animate__fadeInDown',
        fadeClass4:'animate__animated animate__fadeInDown',
        fadeClass5:'animate__animated animate__fadeInDown',

        department:'',
        secondDepartment:'',
        thirdDepartment:'',
        fourthDepartment:'',
        fifthDepartment:'',
        res:{},
        stateFlag:true,
        totalScore:0,
        pmPercent:0,
        srePercent:0,
        webPercent:0,
        desPercent:0,
        rdPercent:0,
        allScore:[],
        userName:'',


        firstDepartmentContent:{},
        secondDepartmentContent:{},
        thirdDepartmentContent:{},
        fourthDepartmentContent:{},
        fifthDepartmentContent:{},


        touchEnd:false,
        outerStyle:{},
        background_grass_style:{zIndex:'0'},
        save_pic_style:{display:'none'},
        symbol_img_style:{display:'none'},
        flashes_style:{opacity:'0',display:'none'},
        
        thirdLineWidth:{width:'270px'},
        scrollStyle:{},
        sreWidth:{},
        lateralGradient1:{},
        lateralGradient2:{},
        lateralGradient3:{},
        lateralGradient4:{},
        lateralGradient5:{},
        valve:1,
        screenshotHeight:{height:'100vh'},
        third_line3_margin:{},
        third_line3_margin2:{},
        department_introduction:{},
        save_pic_style2:{display:'none'}

    }
    componentDidMount(){

                var ua = navigator.userAgent.toLowerCase(); 
                //userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值。
                // if (ua.match(/MicroMessenger/) != null || ua.match(/MicroMessenger/) != null) { 
                // alert(ua.match(/MicroMessenger/i) == 'micromessenger')
                
                function isWx(){
                    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1  //通用
                }
                if(isWx()){
                    if(document.body.clientHeight/document.body.clientWidth>=1.35&&document.body.clientHeight/document.body.clientWidth<=1.5){
                        this.setState({screenshotHeight:{height:'120vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.5&&document.body.clientHeight/document.body.clientWidth<=1.56){
                        this.setState({screenshotHeight:{height:'116vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.56&&document.body.clientHeight/document.body.clientWidth<=1.62){
                        this.setState({screenshotHeight:{height:'110vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.62&&document.body.clientHeight/document.body.clientWidth<=1.7){
                        this.setState({screenshotHeight:{height:'100vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.7&&document.body.clientHeight/document.body.clientWidth<=1.8){
                        this.setState({screenshotHeight:{height:'98vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.8&&document.body.clientHeight/document.body.clientWidth<=1.9){
                        this.setState({screenshotHeight:{height:'94vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.9&&document.body.clientHeight/document.body.clientWidth<=2.0){
                        this.setState({screenshotHeight:{height:'90vh'}})
                        // alert(document.body.clientHeight/document.body.clientWidth)
                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.8&&document.body.clientHeight/document.body.clientWidth<=1.9){
                        this.setState({screenshotHeight:{height:'94vh'}})

                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.9&&document.body.clientHeight/document.body.clientWidth<=2.0){
                        this.setState({screenshotHeight:{height:'90vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>2&&document.body.clientHeight/document.body.clientWidth<=2.1){
                        this.setState({screenshotHeight:{height:'85vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>2.1&&document.body.clientHeight/document.body.clientWidth<=2.2){
                        this.setState({screenshotHeight:{height:'82vh'}})
                        // alert(document.body.clientHeight/document.body.clientWidth)
                    }
                }

                // if (ua.match(/MicroMessenger/i) == 'micromessenger') { 
                //     // alert(1)
                //     // alert(document.body.clientHeight/document.body.clientWidth)
                //     // this.setState({screenshotHeight:{height:'120vh'}})
                //     // this.setState({screenshotHeight:{height:'110vh'}})
                //     if(document.body.clientHeight/document.body.clientWidth>=1.35&&document.body.clientHeight/document.body.clientWidth<=1.5){
                //         this.setState({screenshotHeight:{height:'120vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>=1.5&&document.body.clientHeight/document.body.clientWidth<=1.56){
                //         this.setState({screenshotHeight:{height:'116vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>1.56&&document.body.clientHeight/document.body.clientWidth<=1.62){
                //         this.setState({screenshotHeight:{height:'110vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>=1.62&&document.body.clientHeight/document.body.clientWidth<=1.7){
                //         this.setState({screenshotHeight:{height:'100vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>1.7&&document.body.clientHeight/document.body.clientWidth<=1.8){
                //         this.setState({screenshotHeight:{height:'98vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>1.8&&document.body.clientHeight/document.body.clientWidth<=1.9){
                //         this.setState({screenshotHeight:{height:'94vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>=1.9&&document.body.clientHeight/document.body.clientWidth<=2.0){
                //         this.setState({screenshotHeight:{height:'90vh'}})
                //         // alert(document.body.clientHeight/document.body.clientWidth)
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>1.8&&document.body.clientHeight/document.body.clientWidth<=1.9){
                //         this.setState({screenshotHeight:{height:'94vh'}})

                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>=1.9&&document.body.clientHeight/document.body.clientWidth<=2.0){
                //         this.setState({screenshotHeight:{height:'90vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>2&&document.body.clientHeight/document.body.clientWidth<=2.1){
                //         this.setState({screenshotHeight:{height:'85vh'}})
                //     }
                //     if(document.body.clientHeight/document.body.clientWidth>2.1&&document.body.clientHeight/document.body.clientWidth<=2.2){
                //         this.setState({screenshotHeight:{height:'82vh'}})
                //         // alert(document.body.clientHeight/document.body.clientWidth)
                //     }
                //     // if(document.body.clientHeight/document.body.clientWidth>2.010){
                //     //     // that.setState({screenshotHeight:{height:'83vh'}})
                        
                //     // }
                //     //    alert("微信浏览器"); 
                // } 
                if(ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null){
                    if(document.body.clientHeight/document.body.clientWidth>=1.35&&document.body.clientHeight/document.body.clientWidth<=1.5){
                        this.setState({screenshotHeight:{height:'120vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.5&&document.body.clientHeight/document.body.clientWidth<=1.56){
                        this.setState({screenshotHeight:{height:'116vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.56&&document.body.clientHeight/document.body.clientWidth<=1.62){
                        this.setState({screenshotHeight:{height:'110vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.62&&document.body.clientHeight/document.body.clientWidth<=1.66){
                        this.setState({screenshotHeight:{height:'107vh'}})

                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.66&&document.body.clientHeight/document.body.clientWidth<=1.75){
                        this.setState({screenshotHeight:{height:'103vh'}})

                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.75&&document.body.clientHeight/document.body.clientWidth<=1.8){
                        this.setState({screenshotHeight:{height:'99vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.8&&document.body.clientHeight/document.body.clientWidth<=1.85){
                        this.setState({screenshotHeight:{height:'96vh'}})

                    }
                    if(document.body.clientHeight/document.body.clientWidth>1.85&&document.body.clientHeight/document.body.clientWidth<=1.9){
                        this.setState({screenshotHeight:{height:'94vh'}})

                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.9&&document.body.clientHeight/document.body.clientWidth<=1.96){
                        this.setState({screenshotHeight:{height:'91vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>=1.96&&document.body.clientHeight/document.body.clientWidth<=2.00){
                        this.setState({screenshotHeight:{height:'89vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>2&&document.body.clientHeight/document.body.clientWidth<=2.1){
                        this.setState({screenshotHeight:{height:'86vh'}})
                    }
                    if(document.body.clientHeight/document.body.clientWidth>2.1&&document.body.clientHeight/document.body.clientWidth<=2.2){
                        this.setState({screenshotHeight:{height:'83vh'}})
                    }
                }
                else { 
                    // alert(document.body.clientHeight/document.body.clientWidth)
                    // this.setState({screenshotHeight:{height:'120vh'}})
                    // this.setState({screenshotHeight:{height:'110vh'}})
                    // if(document.body.clientHeight/document.body.clientWidth>=1.900&&document.body.clientHeight/document.body.clientWidth<=2.000){
                    //     this.setState({screenshotHeight:{height:'90vh'}})
                    // }
                    // if(document.body.clientHeight/document.body.clientWidth>=1.900&&document.body.clientHeight/document.body.clientWidth<=2.000){
                    //     this.setState({screenshotHeight:{height:'90vh'}})

                    // }
                    //    alert("不是微信浏览器"); 
                   } 
               
           
           
            // var ua = navigator.userAgent.toLowerCase();
            // if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //     //在微信中打开
            //   }

        // console.log(document.body.clientHeight)
        // console.log(document.body.clientHeight/document.body.clientWidth)
        if(document.body.clientHeight/document.body.clientWidth>=2.000&&document.body.clientHeight/document.body.clientWidth<=2.010){
            this.setState({screenshotHeight:{height:'90vh'}})
        }
        if(document.body.clientHeight/document.body.clientWidth>2.010){
            this.setState({screenshotHeight:{height:'83vh'}})
        }
        // alert(document.body.clientHeight)
        
        const input = localStorage['input']
        this.setState({userName:input})
        // console.log(input)
        const allScoreString = localStorage.getItem('result')
        //解决eval报错问题，重写eval函数
        function evil(fn) {
            var Fn = Function;  
            return new Fn('return ' + fn)();
          }
          
        const allScore = evil(allScoreString)
        this.setState({allScore})
        // console.log(allScore)
        //冒泡排序
        var compare = function (prop) {
            return function (obj1, obj2) {
                var val1 = obj1[prop];
                var val2 = obj2[prop];
                if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                    val1 = Number(val1);
                    val2 = Number(val2);
                }
                if (val1 < val2) {
                    return -1;
                } else if (val1 > val2) {
                    return 1;
                } else {
                    return 0;
                }            
            } 
        }
        allScore.sort(compare("score"))
        let total = 0
        allScore.map(item=>{
            total += item.score
            this.setState({totalScore:total})
            return 0
        })
        
        //总分
        
        
        // console.log(total)
        // console.log(allScore[3].name)
        //分数最高部门
        this.setState({department:allScore[4].name})
        //分数第二名的部门
        this.setState({secondDepartment:allScore[3].name})
        //分数第三名的部门
        this.setState({thirdDepartment:allScore[2].name})
        //分数第四名的部门
        this.setState({fourthDepartment:allScore[1].name})
        //分数第五名的部门
        this.setState({fifthDepartment:allScore[0].name})

    }
    
    //展示部门介绍
    showContent=()=>{
        const {flag} = this.state
        this.setState({flag:!flag})
        if(flag===true){
            this.setState({PMstyle:{},rotateClass:'triangle2',fadeClass:'animate__animated animate__fadeInDown'})
            this.setState({lateralGradient1:{display:'none'}})
        }
        if(flag===false){
            this.setState({rotateClass:'triangle3',fadeClass:'animate__animated animate__fadeOutUp'})
            //解决双击内容显示bug
            setTimeout(()=>{
                this.setState({PMstyle:{display:'none'}})
                this.setState({lateralGradient1:{}})
                if(this.state.flag===false){
                    this.setState({PMstyle:{}})
                }
            },800)
        }
    }
    showContent2=()=>{
        const {flag2} = this.state
        this.setState({flag2:!flag2})
        if(flag2===true){
            this.setState({DESIGNstyle:{},rotateClass2:'triangle2',fadeClass2:'animate__animated animate__fadeInDown'})
            this.setState({lateralGradient2:{display:'none'}})
        }
        if(flag2===false){
            this.setState({rotateClass2:'triangle3',fadeClass2:'animate__animated animate__fadeOutUp'})
            setTimeout(()=>{
                this.setState({DESIGNstyle:{display:'none'}})
                this.setState({lateralGradient2:{}})
                //解决双击内容显示bug
                if(this.state.flag2===false){
                    this.setState({DESIGNstyle:{}})
                }
            },800)
        }
    }
    showContent3=()=>{
        const {flag3} = this.state
        this.setState({flag3:!flag3})
        if(flag3===true){
            this.setState({WEBstyle:{},rotateClass3:'triangle2',fadeClass3:'animate__animated animate__fadeInDown'})
            this.setState({lateralGradient3:{display:'none'}})
        }
        if(flag3===false){
            this.setState({rotateClass3:'triangle3',fadeClass3:'animate__animated animate__fadeOutUp'})
            setTimeout(()=>{
                this.setState({WEBstyle:{display:'none'}})
                this.setState({lateralGradient3:{}})
                //解决双击内容显示bug
                if(this.state.flag3===false){
                    this.setState({WEBstyle:{}})
                }
            },800)
        }
    }
    showContent4=()=>{
        const {flag4} = this.state
        this.setState({flag4:!flag4})
        if(flag4===true){
            this.setState({RDstyle:{},rotateClass4:'triangle2',fadeClass4:'animate__animated animate__fadeInDown'})
            this.setState({lateralGradient4:{display:'none'}})
        }
        if(flag4===false){
            this.setState({rotateClass4:'triangle3',fadeClass4:'animate__animated animate__fadeOutUp'})
            setTimeout(()=>{
                this.setState({RDstyle:{display:'none'}})
                this.setState({lateralGradient4:{}})
                //解决双击内容显示bug
                if(this.state.flag4===false){
                    this.setState({RDstyle:{}})
                }
            },800)
        }
    }
    showContent5=()=>{
        const {flag5} = this.state
        this.setState({flag5:!flag5})
        if(flag5===true){
            this.setState({SREstyle:{},rotateClass5:'triangle2',fadeClass5:'animate__animated animate__fadeInDown'})
            this.setState({lateralGradient5:{display:'none'}})
        }
        if(flag5===false){
            this.setState({rotateClass5:'triangle3',fadeClass5:'animate__animated animate__fadeOutUp'})
            setTimeout(()=>{
                this.setState({SREstyle:{display:'none'}})
                this.setState({lateralGradient5:{}})
                //解决双击内容显示bug
                if(this.state.flag5===false){
                    this.setState({SREstyle:{}})
                }
            },800)
        }
    }
    try_again=()=>{
        clearInterval(this.monitor)
        const localStorage = window.localStorage;
        localStorage.setItem("try_again","flag");
        this.props.history.replace('/Home')
    }
    start_move=()=>{
        
    }
    componentDidUpdate(){
        
        // console.log(this.screenCapture.offsetTop)
        const {department,secondDepartment,thirdDepartment,fourthDepartment,fifthDepartment,stateFlag,allScore,totalScore} = this.state
        // console.log(department)
        // console.log(this.state.thirdDepartment)
        //console.log(allScore)
        allScore.map((item)=>{
            // console.log(allScore)        
            //获取各部门分数占比    
            if(item.name==='移动开发部'){
                let percent = item.score/totalScore
                // console.log(percent)
                this.setState({rdPercent:percent})
            }
            if(item.name==='WEB研发部'){
                let percent = item.score/this.state.totalScore
                this.setState({webPercent:percent})
            }
            if(item.name==='产品策划及运营部'){
                let percent = item.score/this.state.totalScore
                this.setState({pmPercent:percent})
            }
            if(item.name==='运维安全部'){
                let percent = item.score/this.state.totalScore
                this.setState({srePercent:percent})
            }
            if(item.name==='视觉设计部'){
                let percent = item.score/this.state.totalScore
                this.setState({desPercent:percent})
            }
            return 0
        })
        //第一名的部门
        if(department==='移动开发部'&&stateFlag===true){
            this.setState({res:{person:rdTitle,tag1:'#APP大牛',tag2:'#iOS',tag3:'#Andriod',writings1:'你是孤独的思考者',writings2:'你更关注现象背后的本质',writings3:'执着地探求万物背后的规律'},stateFlag:false})
        }
        if(department==='WEB研发部'&&stateFlag===true){
            this.setState({res:{person:webTitle,tag1:'#网页制作',tag2:'#后端',tag3:'#前端',writings1:'你是理性的实干家',writings2:'你往往忠于经验和事实',writings3:'善于依靠自己的能力解决问题'},stateFlag:false,thirdLineWidth:{width:'230px'}})
        }
        if(department==='产品策划及运营部'&&stateFlag===true){
            this.setState({res:{person:pmTitle,tag1:'#需求',tag2:'#沟通',tag3:'#运营',writings1:'你是意志坚定的指挥家',writings2:'你对整个世界抱有好奇心',writings3:'总能以独特的角度发现问题，解决问题'},stateFlag:false,thirdLineWidth:{width:'190px'}})
        }
        if(department==='运维安全部'&&stateFlag===true){
            this.setState({res:{person:sreTitle,tag1:'#安全',tag2:'#运维',tag3:'#Linux',writings1:'你是隐于幕后的贡献者',writings2:'默默的给予身边人安全感',writings3:'你总是能够从混沌中找到逻辑'},stateFlag:false,thirdLineWidth:{width:'210px'},sreWidth:{width:'162px',height:'57px'}})
        }
        if(department==='视觉设计部'&&stateFlag===true){
            this.setState({res:{person:desTitle,tag1:'#灵感',tag2:'#审美',tag3:'#创作',writings1:'你是天马行空的创造者',writings2:'斑斓的缤纷在你脑海里爆发',writings3:'世间的美好将由你来描绘'},stateFlag:false,thirdLineWidth:{width:'190px'}})
        }
        /**
         * 
         * 
         */
        //第一名部门介绍
        if(department==="移动开发部"&&stateFlag===true){
            this.setState({firstDepartmentContent:{department:'移动开发部',motto:'“方寸间世界的创造者”',introduction:'在这里你能得到 Java & Kotlin 或 Objective-C & Swift 的编程技巧，能随心所欲的开发属于自己的App，Android 和 iOS 都将成为你指尖的玩具。'}})
            if(document.body.clientWidth>390){
                this.setState({third_line3_margin:{left:'12px'}})
                this.setState({third_line3_margin2:{left:'20px'}})
            }
        }
        if(department==="产品策划及运营部"&&stateFlag===true){
            this.setState({firstDepartmentContent:{department:'产品策划及运营部',motto:'“创意聚集港，产品造梦厂”',introduction:'我们用设计实现需求，用技术呈现想法，带领团队策划研发最出色产品，打造最优质运营。加入我们，就是现在，一起策划未来。'}})
        }
        if(department==="视觉设计部"&&stateFlag===true){
            this.setState({firstDepartmentContent:{department:'视觉设计部',motto:'“用色彩涂抹梦想，用画笔创造未来”',introduction:'来到视觉，用ps 等创作工具将想法照进现实，让更多的人认识我们独一无二的奇思妙想。迎新海报、重邮地图、卷卷都在这里诞生，期待你的加入，来一起创作多优秀作品！'}})
        }
        if(department==="WEB研发部"&&stateFlag===true){
            this.setState({firstDepartmentContent:{department:'WEB研发部',motto:'“WEB，实现互联网的不可思议”',introduction:'前端打造完美的网络页面，后端建筑坚实的数据支持，上能制作“红岩门面”精致官网，下能玩转“重邮帮”小程序，极致的用户体验由我们创造，精准的交互响应由我们实现。'}})
        }
        if(department==="运维安全部"&&stateFlag===true){
            this.setState({firstDepartmentContent:{department:'运维安全部',motto:'“运维安全在，网校没意外”',introduction:'我们是神秘的白帽黑客，是维护网络安全的行者，是稳定和安全的代名词。在这里，和小伙伴们一起前往Linux的世界，感受计算机底层的技术原理，攀上先进技术栈和极致性能的高峰。'}})
        }
        //第二名部门介绍
        if(secondDepartment==="移动开发部"&&stateFlag===true){
            this.setState({secondDepartmentContent:{department:'移动开发部',motto:'“方寸间世界的创造者”',introduction:'在这里你能得到 Java & Kotlin 或 Objective-C & Swift 的编程技巧，能随心所欲的开发属于自己的App，Android 和 iOS 都将成为你指尖的玩具。'}})
        }
        if(secondDepartment==="产品策划及运营部"&&stateFlag===true){
            this.setState({secondDepartmentContent:{department:'产品策划及运营部',motto:'“创意聚集港，产品造梦厂”',introduction:'我们用设计实现需求，用技术呈现想法，带领团队策划研发最出色产品，打造最优质运营。加入我们，就是现在，一起策划未来。'}})
        }
        if(secondDepartment==="视觉设计部"&&stateFlag===true){
            this.setState({secondDepartmentContent:{department:'视觉设计部',motto:'“用色彩涂抹梦想，用画笔创造未来”',introduction:'来到视觉，用ps 等创作工具将想法照进现实，让更多的人认识我们独一无二的奇思妙想。迎新海报、重邮地图、卷卷都在这里诞生，期待你的加入，来一起创作多优秀作品！'}})
        }
        if(secondDepartment==="WEB研发部"&&stateFlag===true){
            this.setState({secondDepartmentContent:{department:'WEB研发部',motto:'“WEB，实现互联网的不可思议”',introduction:'前端打造完美的网络页面，后端建筑坚实的数据支持，上能制作“红岩门面”精致官网，下能玩转“重邮帮”小程序，极致的用户体验由我们创造，精准的交互响应由我们实现。'}})
        }
        if(secondDepartment==="运维安全部"&&stateFlag===true){
            this.setState({secondDepartmentContent:{department:'运维安全部',motto:'“运维安全在，网校没意外”',introduction:'我们是神秘的白帽黑客，是维护网络安全的行者，是稳定和安全的代名词。在这里，和小伙伴们一起前往Linux的世界，感受计算机底层的技术原理，攀上先进技术栈和极致性能的高峰。'}})
        }
        //第三名部门介绍
        if(thirdDepartment==="移动开发部"&&stateFlag===true){
            this.setState({thirdDepartmentContent:{department:'移动开发部',motto:'“方寸间世界的创造者”',introduction:'在这里你能得到 Java & Kotlin 或 Objective-C & Swift 的编程技巧，能随心所欲的开发属于自己的App，Android 和 iOS 都将成为你指尖的玩具。'}})
        }
        if(thirdDepartment==="产品策划及运营部"&&stateFlag===true){
            this.setState({thirdDepartmentContent:{department:'产品策划及运营部',motto:'“创意聚集港，产品造梦厂”',introduction:'我们用设计实现需求，用技术呈现想法，带领团队策划研发最出色产品，打造最优质运营。加入我们，就是现在，一起策划未来。'}})
        }
        if(thirdDepartment==="视觉设计部"&&stateFlag===true){
            this.setState({thirdDepartmentContent:{department:'视觉设计部',motto:'“用色彩涂抹梦想，用画笔创造未来”',introduction:'来到视觉，用ps 等创作工具将想法照进现实，让更多的人认识我们独一无二的奇思妙想。迎新海报、重邮地图、卷卷都在这里诞生，期待你的加入，来一起创作多优秀作品！'}})
        }
        if(thirdDepartment==="WEB研发部"&&stateFlag===true){
            this.setState({thirdDepartmentContent:{department:'WEB研发部',motto:'“WEB，实现互联网的不可思议”',introduction:'前端打造完美的网络页面，后端建筑坚实的数据支持，上能制作“红岩门面”精致官网，下能玩转“重邮帮”小程序，极致的用户体验由我们创造，精准的交互响应由我们实现。'}})
        }
        if(thirdDepartment==="运维安全部"&&stateFlag===true){
            this.setState({thirdDepartmentContent:{department:'运维安全部',motto:'“运维安全在，网校没意外”',introduction:'我们是神秘的白帽黑客，是维护网络安全的行者，是稳定和安全的代名词。在这里，和小伙伴们一起前往Linux的世界，感受计算机底层的技术原理，攀上先进技术栈和极致性能的高峰。'}})
        }
        //第四名部门介绍
        if(fourthDepartment==="移动开发部"&&stateFlag===true){
            this.setState({fourthDepartmentContent:{department:'移动开发部',motto:'“方寸间世界的创造者”',introduction:'在这里你能得到 Java & Kotlin 或 Objective-C & Swift 的编程技巧，能随心所欲的开发属于自己的App，Android 和 iOS 都将成为你指尖的玩具。'}})
        }
        if(fourthDepartment==="产品策划及运营部"&&stateFlag===true){
            this.setState({fourthDepartmentContent:{department:'产品策划及运营部',motto:'“创意聚集港，产品造梦厂”',introduction:'我们用设计实现需求，用技术呈现想法，带领团队策划研发最出色产品，打造最优质运营。加入我们，就是现在，一起策划未来。'}})
        }
        if(fourthDepartment==="视觉设计部"&&stateFlag===true){
            this.setState({fourthDepartmentContent:{department:'视觉设计部',motto:'“用色彩涂抹梦想，用画笔创造未来”',introduction:'来到视觉，用ps 等创作工具将想法照进现实，让更多的人认识我们独一无二的奇思妙想。迎新海报、重邮地图、卷卷都在这里诞生，期待你的加入，来一起创作多优秀作品！'}})
        }
        if(fourthDepartment==="WEB研发部"&&stateFlag===true){
            this.setState({fourthDepartmentContent:{department:'WEB研发部',motto:'“WEB，实现互联网的不可思议”',introduction:'前端打造完美的网络页面，后端建筑坚实的数据支持，上能制作“红岩门面”精致官网，下能玩转“重邮帮”小程序，极致的用户体验由我们创造，精准的交互响应由我们实现。'}})
        }
        if(fourthDepartment==="运维安全部"&&stateFlag===true){
            this.setState({fourthDepartmentContent:{department:'运维安全部',motto:'“运维安全在，网校没意外”',introduction:'我们是神秘的白帽黑客，是维护网络安全的行者，是稳定和安全的代名词。在这里，和小伙伴们一起前往Linux的世界，感受计算机底层的技术原理，攀上先进技术栈和极致性能的高峰。'}})
        }
        //第五名部门介绍
        if(fifthDepartment==="移动开发部"&&stateFlag===true){
            this.setState({fifthDepartmentContent:{department:'移动开发部',motto:'“方寸间世界的创造者”',introduction:'在这里你能得到 Java & Kotlin 或 Objective-C & Swift 的编程技巧，能随心所欲的开发属于自己的App，Android 和 iOS 都将成为你指尖的玩具。'}})
        }
        if(fifthDepartment==="产品策划及运营部"&&stateFlag===true){
            this.setState({fifthDepartmentContent:{department:'产品策划及运营部',motto:'“创意聚集港，产品造梦厂”',introduction:'我们用设计实现需求，用技术呈现想法，带领团队策划研发最出色产品，打造最优质运营。加入我们，就是现在，一起策划未来。'}})
        }
        if(fifthDepartment==="视觉设计部"&&stateFlag===true){
            this.setState({fifthDepartmentContent:{department:'视觉设计部',motto:'“用色彩涂抹梦想，用画笔创造未来”',introduction:'来到视觉，用ps 等创作工具将想法照进现实，让更多的人认识我们独一无二的奇思妙想。迎新海报、重邮地图、卷卷都在这里诞生，期待你的加入，来一起创作多优秀作品！'}})
        }
        if(fifthDepartment==="WEB研发部"&&stateFlag===true){
            this.setState({fifthDepartmentContent:{department:'WEB研发部',motto:'“WEB，实现互联网的不可思议”',introduction:'前端打造完美的网络页面，后端建筑坚实的数据支持，上能制作“红岩门面”精致官网，下能玩转“重邮帮”小程序，极致的用户体验由我们创造，精准的交互响应由我们实现。'}})
        }
        if(fifthDepartment==="运维安全部"&&stateFlag===true){
            this.setState({fifthDepartmentContent:{department:'运维安全部',motto:'“运维安全在，网校没意外”',introduction:'我们是神秘的白帽黑客，是维护网络安全的行者，是稳定和安全的代名词。在这里，和小伙伴们一起前往Linux的世界，感受计算机底层的技术原理，攀上先进技术栈和极致性能的高峰。'}})
        }
        //高度监测，自动弹出部门介绍
        const number2 = 1
        let valve2 = number2
        try {
            // console.log(415)
            this.monitor = setInterval(()=>{
                    if(this.state.valve===1&&valve2===1){
                        let presentHeight
                        if(this.firstDepartment!==null){

                            presentHeight = this.firstDepartment.getBoundingClientRect()
                            // console.log(valve2,this.state.valve)
                            // if(presentHeight.top!==undefined)
                            if(presentHeight.top<=600&&this.state.valve===1){
                                // console.log(421)
                                valve2 = valve2 + 1
                                const {flag} = this.state
                                this.setState({valve:2})
                                this.setState({flag:!flag})
                                this.setState({lateralGradient1:{display:'none'}})
                                this.setState({PMstyle:{},rotateClass:'triangle2',fadeClass:'animate__animated animate__fadeInDown'})
                                clearInterval(this.monitor)
                            }
                            if(this.state.valve===1){
                                clearInterval(this.monitor)
                            }
                            if(this.state.valve===2){
                                clearInterval(this.monitor)
                            }
                        }
                    }
                    // const number = 1
                    // valve = number
                    /**
                     * getBoundingClientRect()这个方法会在dom变更时报错
                     */
                },500) 
        } catch (error) {
            console.log(error)
        }
    }
    componentWillUnmount(){
        setInterval(()=>{
            clearInterval(this.monitor)
        },100)
    }
    save_pic=(event)=>{
        //event.preventDefault()  
        // const {outerStyle,background_grass_style} = this.state
        //延时的目的在于截图时截的是二维码
        this.setState({splitLineStyle:{display:"none"}})
        this.setState({symbol_img_style:{}})
        this.setState({department_introduction:{display:'none'}})
        // const opacity = 1 
        // let presentOpacity = opacity
        // const falshinterval = setInterval(() => {
        //     presentOpacity = presentOpacity-0.05
        //     this.setState({flashes_style:{opacity:presentOpacity}})
        //     if(presentOpacity<=0){
        //         this.setState({flashes_style:{display:'none'}})
        //         clearInterval(falshinterval)
        //     }
        // }, 10);
        setTimeout(()=>{
            // this.setState({rotateClass:'triangle1',rotateClass2:'triangle1',rotateClass3:'triangle1',rotateClass4:'triangle1',rotateClass5:'triangle1',})
            this.setState({symbol_img_style:{}})
            this.setState({btnStyle:{display:'none'}})
            this.setState({save_pic:{}})
            // this.setState({
            //     mashroom_bottom:{
            //         transform: 'translateX(-18px) translateY(-200px)'
            //     },
            //     splitLineStyle :{
            //         transform: 'translateY(30px)'
            //     }
            // })
            this.setState({mashroom_bottom:{top:'-50px'}})
            this.setState({outerStyle:{display:'none'}})
            html2canvas(this.screenshot,{
                dpi: window.devicePixelRatio*300,
                height: document.getElementById("screenshot").clientHeight - 2,
                width: document.getElementById("screenshot").clientWidth - 2,
                imageTimeout:0
            }).then(canvas=>{
                const save_img = document.getElementById('save_img')
                // canvas.getContext("2d").translate(100,100)
                const url = canvas.toDataURL();
                save_img.src = url
                let symbol_img_style = document.getElementById('symbol_img_style')
                // console.log('截图高度为')
                setTimeout(()=>{

                    const screenCapture = this.screenCapture.getBoundingClientRect().height
                    const closeHeight = symbol_img_style.getBoundingClientRect().height
                    // console.log(screenCapture,closeHeight)
                    const closeOffsetHeight = (closeHeight-screenCapture)/2
                    // console.log(closeOffsetHeight)
                    const newHeight = closeOffsetHeight + screenCapture-67
                    // console.log(newHeight)
                    this.setState({save_pic_style:{top:`${closeOffsetHeight}px`}})
                    this.setState({save_pic_style2:{top:`${newHeight}px`}})
                },10)

            })
        },0)
        // 
    }
    // touchStart=()=>{
        
    // }
    // touchEnd=()=>{
    //     clearTimeout(this.timer)
    // }
    closeImg=()=>{
        this.setState({department_introduction:{}})
        this.setState({save_pic_style:{display:'none'}})
        this.setState({save_pic_style2:{display:'none'}})
        this.setState({symbol_img_style:{display:'none'}})
        this.setState({btnStyle:{}})
        this.setState({save_pic:{display:'none'}})
        this.setState({mashroom_bottom:{top:'-100px'},splitLineStyle:{}})
    }
    join_us=()=>{
        clearInterval(this.monitor)
        this.props.history.replace('/Join_us')
    }
    render() {
        const {save_pic,btnStyle,splitLineStyle,mashroom_bottom,PMstyle,DESIGNstyle,rotateClass,rotateClass2,fadeClass,fadeClass2,
            rotateClass3,fadeClass3,WEBstyle,rotateClass4,fadeClass4,RDstyle,rotateClass5,fadeClass5,SREstyle,department,secondDepartment,
            thirdDepartment,fourthDepartment,fifthDepartment,res,userName,firstDepartmentContent,secondDepartmentContent,thirdDepartmentContent,
            fourthDepartmentContent,fifthDepartmentContent,pmPercent,rdPercent,webPercent,srePercent,desPercent,save_pic_style,symbol_img_style,
            thirdLineWidth,sreWidth,lateralGradient1,lateralGradient2,lateralGradient3,lateralGradient4,lateralGradient5,screenshotHeight,third_line3_margin,third_line3_margin2,
            department_introduction,save_pic_style2
        } = this.state
        return (
            <Fragment>
                <div style={symbol_img_style} id="symbol_img_style"
                 >
                    <img src="" alt="" id="save_img" style={{width:'100%'}} ref={node=>this.screenCapture=node}/>
                </div>
                <div id="outerBackground"></div>
                <div className="scorll" ref={node=>this.scorll = node}>
                    
                    {/* <div className="flashes" style={flashes_style}></div> */}
                    <div className="canvas_container" ref={node=>this.canvas_container=node}></div>
                    <img src={close} alt="err" id="close" style={save_pic_style} onClick={this.closeImg}/>

                    <div id="underqrCode" style={save_pic_style2}>(长按保存图片)</div>
                    <div className="res_outer" onClick={this.start_move} ref={node=>this.outer = node} > 
                            {/* <img src={background_grass} alt="err" id="background_grass"/> */}
                        <div style={screenshotHeight} ref={node=>this.screenshot=node} id="screenshot">
                            <div className="content">
                                {/* <img src={bigGradient} alt="err" id="bigGradient"/> */}
                                <img src={gradient} alt="err" id="gradient"/>
                                {/* <img src={small_grass} alt="err" id="small_grass"/> */}

                                <div id="first_line">{userName}的红岩人设是：
                                </div>
                                {/* <div id="second_line">{res.person}</div> */}
                                {/* title */}
                                <img src={res.person} alt="err" id="second_line" style={sreWidth}/>
                                <div id="third_line" style={thirdLineWidth}>
                                    <div className="third_line1">
                                    {res.tag1}
                                    <div className="third_line1_blueline"></div>
                                    </div>
                                    <div className="third_line2" style={third_line3_margin}>
                                    {res.tag2}           
                                    <div className="third_line2_blueline"></div>
                                    </div>
                                    <div className="third_line3" style={third_line3_margin2}>
                                    {res.tag3}
                                    <div className="third_line3_blueline"></div>
                                    </div>
                                </div>
                                <div id="fourth_line">{res.writings1}<br/>{res.writings2}<br/>{res.writings3}
                                </div>
                                <div id="fifth_line">
                                    <span id="halo_line"></span>
                                    <span id="sign">BY REDROCK</span>
                                </div>
                                <img src={fabric} alt="err" id="fabric_pic"/>
                                <img src={butterfly} alt="err" id="butterfly"/>
                            </div>
                            <div className="bottom_content">
                                <img src={res_static_firefly} alt="err" id="res_static_firefly" ref={node=>this.bottom_content=node}/>
                                <div className="bottom_top">
                                    <img src={small_icon} alt="err" id="small_icon"/>
                                    {'\u00A0'}同时你也可以尝试
                                    <br/>
                                    <span className="department">{this.state.secondDepartment}{'\u00A0'}{'\u00A0'}{this.state.thirdDepartment}</span>
                                </div>
                                <div id="save_pic" style={btnStyle} onTouchStart={this.touchStart} onTouchEnd={this.touchEnd}>
                                    分享图片
                                </div>
                                <div className="save_pic_empty" onClick={this.save_pic}></div>
                                <div className="bottom_body">
                                    <div className="qrCode" style={save_pic}>
                                        <img src={qrCode} alt="err" id="qrCode"/>
                                        <div className="underqrCode">快来测测你的红岩</div>
                                        <div className="underqrCode" style={{marginTop:'-5px'}}>人设吧</div>
                                    </div>
                                    <div className="radar">
                                        {/* <Radar/> */}
                                        <NewRadar total={this.state.totalScore} rdPercent={this.state.rdPercent} desPercent={this.state.desPercent} webPercent={this.state.webPercent} srePercent={this.state.srePercent} pmPercent={this.state.pmPercent}/>
                                    </div>
                                </div>
                                <div className="mashroomOuter" style={mashroom_bottom}>
                                    <img src={new_mashroom} alt="err" id="mashroom"/>
                                </div>
                            </div>
                            <img style={splitLineStyle} src={splitLine} alt="err" id="splitLine"/>
                        </div>
                        <div className="department_introduction" style={department_introduction}>
                            <div className="department_header">
                                部门介绍
                            </div>
                            <div className="department" style={{marginTop:'0px'}} ref={node=>this.firstDepartment=node}>
                                <div className="department__header">
                                    <div className="department_header_top">
                                        <div className="double_slash">
                                            <div className="slash"></div>
                                            <div className="slash"></div>
                                        </div>
                                        {
                                            department==='产品策划及运营部'?<><div className="symbol_header">产品策划及运营部</div><div className="blueLine" style={{width:'60.8%'}}/></>:department==='WEB研发部'?<><div className="symbol_header" style={{left:'128px'}}>WEB研发部</div><div className="blueLine" style={{width:'43.3%'}}/></>:department==='视觉设计部' ?<><div className="symbol_header" style={{left:'128px'}}>视觉设计部</div><div className="blueLine" style={{width:'41.3%'}}/></>:department==='移动开发部' ?<><div className="symbol_header" style={{left:'128px'}}>移动开发部</div><div className="blueLine" style={{width:'41.3%'}}/></>:department==='运维安全部'?<><div className="symbol_header" style={{left:'125px'}}>运维安全部</div><div className="blueLine" style={{width:'41.3%'}}/></>:null
                                        
                                        }
                                        {department==='产品策划及运营部'?<span className="percent" style={{left:'65.5%'}}>{Math.round(pmPercent*100)}%</span>:department==='WEB研发部'?<span className="percent" style={{left:'49.3%'}}>{Math.round(webPercent*100)}%</span>:department==='视觉设计部'?<span className="percent" style={{left:'47.3%'}}>{Math.round(desPercent*100)}%</span>:department==='运维安全部'?<span className="percent" style={{left:'46.2%'}}>{Math.round(srePercent*100)}%</span>:department==='移动开发部'?<span className="percent" style={{left:'45.9%'}}>{Math.round(rdPercent*100)}%</span>:null}
                                        <img src={triangle} alt="err" className={rotateClass} onClick={this.showContent} ref={node =>this.triangle1 = node}/>
                                    </div>
                                        <img src={default_halo} alt="err" className="default_halo" style={lateralGradient1}/>
                                        <div className="motto">{firstDepartmentContent.motto}{department==='产品策划及运营部'?<img src={PM} alt="err" className="department_logo"/>:department==='WEB研发部'?<img src={WEB} alt="err" className="department_logo" style={{width:'120px'}}/>:department==='视觉设计部'?<img src={DESIGN} alt="err" className="department_logo" style={{width:'200px'}}/>:department==='运维安全部'?<img src={SRE} alt="err" className="department_logo" style={{width:'120px'}}/>:department==='移动开发部'?<img src={RD} alt="err" className="department_logo" style={{width:'90px'}}/>:null}</div>
                                </div>
                                <div style={PMstyle} className={fadeClass}>
                                    <div className="department_content" style={{marginLeft:'50px'}}>{firstDepartmentContent.introduction}</div>
                                    <div className="department_halo"></div>
                                </div>
                            </div>
                            <div className="department">
                                <div className="department__header">
                                    <div className="department_header_top">
                                        <div className="double_slash">
                                            <div className="slash"></div>
                                            <div className="slash"></div>
                                        {/* </div>
                                            <div className="symbol_header" style={{left:'128px'}}>{secondDepartmentContent.department}</div><span className="percent" style={{left:'20px'}}>{secondDepartment==='产品策划及运营部'?`${Math.round(pmPercent*100)}%`:secondDepartment==='WEB研发部'?`${Math.round(webPercent*100)}%`:secondDepartment==='视觉设计部'?`${Math.round(desPercent*100)}%`:secondDepartment==='运维安全部'?`${Math.round(srePercent*100)}%`:secondDepartment==='移动开发部'?`${Math.round(rdPercent*100)}%`:null}</span><div className="blueLine" style={{width:'162px'}}>
                                        */}
                                        </div> 
                                        {
                                            secondDepartment==='产品策划及运营部'?<><div className="symbol_header">产品策划及运营部</div><div className="blueLine" style={{width:'60.8%'}}/></>:secondDepartment==='WEB研发部'?<><div className="symbol_header" style={{left:'128px'}}>WEB研发部</div><div className="blueLine" style={{width:'43.3%'}}/></>:secondDepartment==='视觉设计部' ?<><div className="symbol_header" style={{left:'128px'}}>视觉设计部</div><div className="blueLine" style={{width:'41.3%'}}/></>:secondDepartment==='移动开发部' ?<><div className="symbol_header" style={{left:'128px'}}>移动开发部</div><div className="blueLine" style={{width:'41.3%'}}/></>:secondDepartment==='运维安全部'?<><div className="symbol_header" style={{left:'125px'}}>运维安全部</div><div className="blueLine" style={{width:'41.3%'}}/></>:null
                                        }
                                        {secondDepartment==='产品策划及运营部'?<span className="percent" style={{left:'65.5%'}}>{Math.round(pmPercent*100)}%</span>:secondDepartment==='WEB研发部'?<span className="percent" style={{left:'49.3%'}}>{Math.round(webPercent*100)}%</span>:secondDepartment==='视觉设计部'?<span className="percent" style={{left:'47.3%'}}>{Math.round(desPercent*100)}%</span>:secondDepartment==='运维安全部'?<span className="percent" style={{left:'46.2%'}}>{Math.round(srePercent*100)}%</span>:secondDepartment==='移动开发部'?<span className="percent" style={{left:'45.9%'}}>{Math.round(rdPercent*100)}%</span>:null}

                                        <img src={triangle} alt="err" className={rotateClass2} onClick={this.showContent2}/>
                                    </div>
                                        <img src={default_halo} alt="err" className="default_halo" style={lateralGradient2}/>
                                        <div className="motto">{secondDepartmentContent.motto}{secondDepartment==='产品策划及运营部'?<img src={PM} alt="err" className="department_logo"/>:secondDepartment==='WEB研发部'?<img src={WEB} alt="err" className="department_logo" style={{width:'120px'}}/>:secondDepartment==='视觉设计部'?<img src={DESIGN} alt="err" className="department_logo" style={{width:'200px'}}/>:secondDepartment==='运维安全部'?<img src={SRE} alt="err" className="department_logo" style={{width:'120px'}}/>:secondDepartment==='移动开发部'?<img src={RD} alt="err" className="department_logo" style={{width:'90px'}}/>:null}</div>
                                </div>
                                <div style={DESIGNstyle} className={fadeClass2}>
                                    <div className="department_content" style={{width:'80%',marginLeft: '50px'}}>{secondDepartmentContent.introduction}</div>
                                    <div className="department_halo"></div>
                                </div>
                            </div>
                            <div className="department">
                                <div className="department__header">
                                    <div className="department_header_top">
                                        <div className="double_slash">
                                            <div className="slash"></div>
                                            <div className="slash"></div>
                                        </div>
                                            {/* <div className="symbol_header" style={{left:'128px'}}>{thirdDepartmentContent.department}</div><span className="percent" style={{left:'35px'}}>{thirdDepartment==='产品策划及运营部'?`${Math.round(pmPercent*100)}%`:thirdDepartment==='WEB研发部'?`${Math.round(webPercent*100)}%`:thirdDepartment==='视觉设计部'?`${Math.round(desPercent*100)}%`:thirdDepartment==='运维安全部'?`${Math.round(srePercent*100)}%`:thirdDepartment==='移动开发部'?`${Math.round(rdPercent*100)}%`:null}</span><div className="blueLine" style={{width:'176px'}}>
                                        </div> */}
                                        {
                                            thirdDepartment==='产品策划及运营部'?<><div className="symbol_header">产品策划及运营部</div><div className="blueLine" style={{width:'60.8%'}}/></>:thirdDepartment==='WEB研发部'?<><div className="symbol_header" style={{left:'128px'}}>WEB研发部</div><div className="blueLine" style={{width:'43.3%'}}/></>:thirdDepartment==='视觉设计部' ?<><div className="symbol_header" style={{left:'128px'}}>视觉设计部</div><div className="blueLine" style={{width:'41.3%'}}/></>:thirdDepartment==='移动开发部' ?<><div className="symbol_header" style={{left:'128px'}}>移动开发部</div><div className="blueLine" style={{width:'41.3%'}}/></>:thirdDepartment==='运维安全部'?<><div className="symbol_header" style={{left:'125px'}}>运维安全部</div><div className="blueLine" style={{width:'41.3%'}}/></>:null
                                        }
                                        {thirdDepartment==='产品策划及运营部'?<span className="percent" style={{left:'65.5%'}}>{Math.round(pmPercent*100)}%</span>:thirdDepartment==='WEB研发部'?<span className="percent" style={{left:'49.3%'}}>{Math.round(webPercent*100)}%</span>:thirdDepartment==='视觉设计部'?<span className="percent" style={{left:'47.3%'}}>{Math.round(desPercent*100)}%</span>:thirdDepartment==='运维安全部'?<span className="percent" style={{left:'46.2%'}}>{Math.round(srePercent*100)}%</span>:thirdDepartment==='移动开发部'?<span className="percent" style={{left:'45.9%'}}>{Math.round(rdPercent*100)}%</span>:null}

                                        <img src={triangle} alt="err" className={rotateClass3} onClick={this.showContent3}/>
                                    </div>
                                        <img src={default_halo} alt="err" className="default_halo" style={lateralGradient3}/>
                                        <div className="motto">{thirdDepartmentContent.motto}{thirdDepartment==='产品策划及运营部'?<img src={PM} alt="err" className="department_logo"/>:thirdDepartment==='WEB研发部'?<img src={WEB} alt="err" className="department_logo" style={{width:'120px'}}/>:thirdDepartment==='视觉设计部'?<img src={DESIGN} alt="err" className="department_logo" style={{width:'200px'}}/>:thirdDepartment==='运维安全部'?<img src={SRE} alt="err" className="department_logo" style={{width:'120px'}}/>:thirdDepartment==='移动开发部'?<img src={RD} alt="err" className="department_logo" style={{width:'90px'}}/>:null}</div>
                                </div>
                                <div style={WEBstyle} className={fadeClass3}>
                                    <div className="department_content" style={{marginLeft: '40px'}}>{thirdDepartmentContent.introduction}</div>
                                    <div className="department_halo"></div>
                                </div>
                            </div>
                            <div className="department">
                                <div className="department__header">
                                    <div className="department_header_top">
                                        <div className="double_slash">
                                            <div className="slash"></div>
                                            <div className="slash"></div>
                                        </div>
                                            {/* <div className="symbol_header" style={{left:'128px'}}>{fourthDepartmentContent.department}</div><span className="percent" style={{left:'35px'}}>{fourthDepartment==='产品策划及运营部'?`${Math.round(pmPercent*100)}%`:fourthDepartment==='WEB研发部'?`${Math.round(webPercent*100)}%`:fourthDepartment==='视觉设计部'?`${Math.round(desPercent*100)}%`:fourthDepartment==='运维安全部'?`${Math.round(srePercent*100)}%`:fourthDepartment==='移动开发部'?`${Math.round(rdPercent*100)}%`:null}</span><div className="blueLine" style={{width:'176px'}}>
                                        </div> */}
                                        {
                                            fourthDepartment==='产品策划及运营部'?<><div className="symbol_header">产品策划及运营部</div><div className="blueLine" style={{width:'60.8%'}}/></>:fourthDepartment==='WEB研发部'?<><div className="symbol_header" style={{left:'128px'}}>WEB研发部</div><div className="blueLine" style={{width:'43.3%'}}/></>:fourthDepartment==='视觉设计部' ?<><div className="symbol_header" style={{left:'128px'}}>视觉设计部</div><div className="blueLine" style={{width:'41.3%'}}/></>:fourthDepartment==='移动开发部' ?<><div className="symbol_header" style={{left:'128px'}}>移动开发部</div><div className="blueLine" style={{width:'41.3%'}}/></>:fourthDepartment==='运维安全部'?<><div className="symbol_header" style={{left:'125px'}}>运维安全部</div><div className="blueLine" style={{width:'41.3%'}}/></>:null
                                        }
                                        {fourthDepartment==='产品策划及运营部'?<span className="percent" style={{left:'65.5%'}}>{Math.round(pmPercent*100)}%</span>:fourthDepartment==='WEB研发部'?<span className="percent" style={{left:'49.3%'}}>{Math.round(webPercent*100)}%</span>:fourthDepartment==='视觉设计部'?<span className="percent" style={{left:'47.3%'}}>{Math.round(desPercent*100)}%</span>:fourthDepartment==='运维安全部'?<span className="percent" style={{left:'46.2%'}}>{Math.round(srePercent*100)}%</span>:fourthDepartment==='移动开发部'?<span className="percent" style={{left:'45.9%'}}>{Math.round(rdPercent*100)}%</span>:null}

                                        <img src={triangle} alt="err" className={rotateClass4} onClick={this.showContent4}/>
                                    </div>
                                        <img src={default_halo} alt="err" className="default_halo" style={lateralGradient4}/>
                                        <div className="motto">{fourthDepartmentContent.motto}{fourthDepartment==='产品策划及运营部'?<img src={PM} alt="err" className="department_logo"/>:fourthDepartment==='WEB研发部'?<img src={WEB} alt="err" className="department_logo" style={{width:'120px'}}/>:fourthDepartment==='视觉设计部'?<img src={DESIGN} alt="err" className="department_logo" style={{width:'200px'}}/>:fourthDepartment==='运维安全部'?<img src={SRE} alt="err" className="department_logo" style={{width:'120px'}}/>:fourthDepartment==='移动开发部'?<img src={RD} alt="err" className="department_logo" style={{width:'90px'}}/>:null}</div>
                                </div>
                                <div style={RDstyle} className={fadeClass4}>
                                    <div className="department_content" style={{marginLeft: '40px'}}>{fourthDepartmentContent.introduction}</div>
                                    <div className="department_halo"></div>
                                </div>
                            </div>
                            <div className="department">
                                <div className="department__header">
                                    <div className="department_header_top">
                                        <div className="double_slash">
                                            <div className="slash"></div>
                                            <div className="slash"></div>
                                        </div>
                                            {/* <div className="symbol_header" style={{left:'128px'}}>{fifthDepartmentContent.department}</div><span className="percent" style={{left:'35px'}}>{fifthDepartment==='产品策划及运营部'?`${Math.round(pmPercent*100)}%`:fifthDepartment==='WEB研发部'?`${Math.round(webPercent*100)}%`:fifthDepartment==='视觉设计部'?`${Math.round(desPercent*100)}%`:fifthDepartment==='运维安全部'?`${Math.round(srePercent*100)}%`:fifthDepartment==='移动开发部'?`${Math.round(rdPercent*100)}%`:null}</span><div className="blueLine" style={{width:'176px'}}>
                                        </div> */}
                                        {
                                            fifthDepartment==='产品策划及运营部'?<><div className="symbol_header">产品策划及运营部</div><div className="blueLine" style={{width:'60.8%'}}/></>:fifthDepartment==='WEB研发部'?<><div className="symbol_header" style={{left:'128px'}}>WEB研发部</div><div className="blueLine" style={{width:'43.3%'}}/></>:fifthDepartment==='视觉设计部' ?<><div className="symbol_header" style={{left:'128px'}}>视觉设计部</div><div className="blueLine" style={{width:'41.3%'}}/></>:fifthDepartment==='移动开发部' ?<><div className="symbol_header" style={{left:'128px'}}>移动开发部</div><div className="blueLine" style={{width:'41.3%'}}/></>:fifthDepartment==='运维安全部'?<><div className="symbol_header" style={{left:'125px'}}>运维安全部</div><div className="blueLine" style={{width:'41.3%'}}/></>:null
                                        }
                                        {fifthDepartment==='产品策划及运营部'?<span className="percent" style={{left:'65.5%'}}>{Math.round(pmPercent*100)}%</span>:fifthDepartment==='WEB研发部'?<span className="percent" style={{left:'49.3%'}}>{Math.round(webPercent*100)}%</span>:fifthDepartment==='视觉设计部'?<span className="percent" style={{left:'47.3%'}}>{Math.round(desPercent*100)}%</span>:fifthDepartment==='运维安全部'?<span className="percent" style={{left:'46.2%'}}>{Math.round(srePercent*100)}%</span>:fifthDepartment==='移动开发部'?<span className="percent" style={{left:'45.9%'}}>{Math.round(rdPercent*100)}%</span>:null}

                                        <img src={triangle} alt="err" className={rotateClass5} onClick={this.showContent5}/>
                                    </div>
                                        <img src={default_halo} alt="err" className="default_halo" style={lateralGradient5}/>
                                        <div className="motto">{fifthDepartmentContent.motto}{fifthDepartment==='产品策划及运营部'?<img src={PM} alt="err" className="department_logo"/>:fifthDepartment==='WEB研发部'?<img src={WEB} alt="err" className="department_logo" style={{width:'120px'}}/>:fifthDepartment==='视觉设计部'?<img src={DESIGN} alt="err" className="department_logo" style={{width:'200px'}}/>:fifthDepartment==='运维安全部'?<img src={SRE} alt="err" className="department_logo" style={{width:'120px'}}/>:fifthDepartment==='移动开发部'?<img src={RD} alt="err" className="department_logo" style={{width:'90px'}}/>:null}</div>
                                </div>
                                <div style={SREstyle} className={fadeClass5}>
                                    <div className="department_content" style={{marginLeft: '40px'}}>{fifthDepartmentContent.introduction}</div>
                                    <div className="department_halo"></div>
                                </div>
                            </div>
                            <div className="res_footer">
                                <div className="bottomBtns">
                                    <div className="bottomBtn" onClick={this.try_again}>再测一次</div>
                                    <div className="bottomBtn" onClick={this.join_us}>加入我们</div>
                                </div>
                                <img src={bottom_halo} alt="err" id="bottom_halo"/>
                                <img src={bottom_grass} alt="err" id="bottom_grass"/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Fragment>
        )
    }
}
