import React, { Component } from 'react'
import './question.scss'
import "animate.css"
import QuestionPage from './questionPage'
import complete from '../../assets/image/question/zipFinally.jpg'
import PubSub from 'pubsub-js'
export default class Questions extends Component {
    coverPage=React.createRef()
    question_index = React.createRef()
    state = {
        show: false,
        className: 'question_index animate__animated',
        question_index_content:"question_index_content animate__animated animate__fadeInDown"
    }
    // coverPage=React.createRef()
    // question_index = React.createRef()
  
    hiddenIndex = () => {
        this.setState({ 
            className: "question_index animate__animated animate__faster 800ms animate__fadeOut", 
            show: true,
            question_index_content:"question_index_content animate__animated  animate__faster 800ms  animate__fadeOut"
         })
        setTimeout(() => {
            this.question_index.current.style.display = "none"
        }, 1000)
    }
    componentDidMount(){
      this.token=  PubSub.subscribe('unshowCover',(_,data)=>{
            this.coverPage.current.style.display='none'
            console.log('aaaaaa');
        })
    this.token1=PubSub.subscribe('showCover',(_,data)=>{
            this.coverPage.current.style.display='block'
            console.log('aaaaaa');
        })
    
    }
    componentWillUnmount(){
        PubSub.unsubscribe(this.token)
        PubSub.unsubscribe(this.token1)
    }
    render() {
        return (
            <div className="question_hidden">
                <div className="coverPage" ref={this.coverPage}></div>
                <QuestionPage show={this.state.show} />
                <div className={this.state.className} onClick={this.hiddenIndex} ref={this.question_index}>
                    <img src={complete} alt="err"></img>
                    {/* <div className="question_index_content animate__animated animate__fadeInDown">
                        欢迎来到红岩小世界，点击画面任意<br />
                        地方开始你的测试冒险吧
                    </div> */}

                </div>
                <div className={this.state.question_index_content}>
                        欢迎来到红岩小世界，点击画面任意<br />
                        地方开始你的测试冒险吧
                    </div>
            </div>
        )
    }
}
